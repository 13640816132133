import { Spinner as Loading } from '@smartsheet/lodestar-core'
import React from 'react'

const Spinner = () => {
  return (
    <div className='spinner-container' role='alert' aria-live='polite'>
      <Loading size='xLarge' />
    </div>
  )
}

export default Spinner
