import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectCurrentMapping,
  updateCurrentMapping
} from '../common/store/mapping'
import { MAPPING_TYPES, getMappingType } from '../common/util/mapping'
import DocHandler from './DocHandler'
import SheetData from './SheetData'

import './Editor.css'

const Editor = ({ setIsWizardOpen, setIsEditorOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentMapping = useSelector(selectCurrentMapping)

  /**
   * setFieldPairing
   * @param {string} identifier Field ObjectNumber or Tab ID
   * @param {object} fillSource {type, value, columnId}
   */
  const setFieldPairing = (identifier, fillSource) => {
    const mappingType = getMappingType(currentMapping)

    if (mappingType === MAPPING_TYPES.native) {
      const pairings = {
        ...currentMapping.fieldPairings,
        [identifier]: fillSource
      }
      dispatch(
        updateCurrentMapping({
          fieldPairings: omitBy(pairings, isNil)
        })
      )
    } else if (mappingType === MAPPING_TYPES.docusign) {
      const pairings = {
        ...currentMapping.docusign.tabPairings,
        [identifier]: fillSource
      }
      dispatch(
        updateCurrentMapping({
          docusign: {
            ...currentMapping.docusign,
            tabPairings: omitBy(pairings, isNil)
          }
        })
      )
    }
  }

  /**
   * setOutputNameColumn
   * @param {object} fillSource {type, name, columnId}
   */
  const setOutputNameColumn = (fillSource) => {
    dispatch(
      updateCurrentMapping({
        outputName: fillSource
      })
    )
  }

  return (
    <div className='editor'>
      <div className='left-column'>
        <div className='info-panel'>
          <h1>{t('sheetSelector.header')}</h1>
          <p>{t('sheetSelector.suggestion')}</p>
          <h3>{t('sheetSelector.columnCardHeader')}</h3>
        </div>
        <SheetData
          setFieldPairing={setFieldPairing}
          setOutputNameColumn={setOutputNameColumn}
        />
      </div>
      <div className='right-column'>
        <DocHandler
          setIsWizardOpen={setIsWizardOpen}
          setIsEditorOpen={setIsEditorOpen}
          setFieldPairing={setFieldPairing}
          setOutputNameColumn={setOutputNameColumn}
        />
      </div>
    </div>
  )
}

Editor.propTypes = {
  setIsWizardOpen: PropTypes.func.isRequired,
  setIsEditorOpen: PropTypes.func.isRequired
}

export default Editor
