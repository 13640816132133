import { TypeRamp } from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectSheet } from '../../common/store/app'
import {
  selectDocusignAuth,
  selectTemplateById
} from '../../common/store/docusign'
import { selectCurrentMapping } from '../../common/store/mapping'
import { flattenRecipientsList } from '../../common/util/docusign'
import { formatRecipientPairing } from './SettingsReviewerHelpers'
import StepReviewRow from './StepReviewRow'

import './SettingsReviewer.css'

const SettingsReviewer = ({ setStep, setComplete }) => {
  const { t } = useTranslation()
  const sheet = useSelector(selectSheet)
  const currentMapping = useSelector(selectCurrentMapping)
  const docusignAuth = useSelector(selectDocusignAuth)

  const template = useSelector(
    selectTemplateById(currentMapping.docusign.templateId)
  )

  useEffect(() => {
    setComplete()
  }, [])

  const formattedPairings = () => {
    if (!template || !currentMapping.docusign.recipients) return
    return flattenRecipientsList(template.recipients).map((rec) =>
      formatRecipientPairing(rec, currentMapping, sheet, t)
    )
  }

  const statusColumn = sheet.columns.find(
    (col) => col.id === currentMapping.docusign.statusColumnId
  )

  const tempName = currentMapping.temp?.statusColumnName

  const dsStatusColumnName = statusColumn ? statusColumn.title : tempName

  return (
    <div className='settings-reviewer'>
      <div className='copy-container'>
        <TypeRamp variant='headlineMedium' renderAs='h2'>
          {t('wizard.screens.docusign.review.header')}
        </TypeRamp>
      </div>
      <div className='grid-container'>
        <>
          <StepReviewRow
            stepName={t('wizard.screens.docusign.auth.stepName')}
            content={t('wizard.screens.docusign.auth.currentUser', {
              user: docusignAuth.user.userName
            })}
            editCommand={() => setStep(1)}
          />
          <StepReviewRow
            stepName={t('wizard.screens.docusign.templateSelector.stepName')}
            content={template?.name || ''}
            editCommand={() => setStep(2)}
          />
          <StepReviewRow
            stepName={t('wizard.screens.docusign.roleAssigner.stepName')}
            content={formattedPairings()}
            editCommand={() => setStep(3)}
          />
          {dsStatusColumnName && (
            <StepReviewRow
              stepName={t(
                'wizard.screens.docusign.statusColumnConfig.stepName'
              )}
              content={dsStatusColumnName}
              editCommand={() => setStep(4)}
            />
          )}
        </>
      </div>
    </div>
  )
}

SettingsReviewer.propTypes = {
  setStep: PropTypes.func.isRequired,
  isInitialized: PropTypes.bool.isRequired,
  setComplete: PropTypes.func.isRequired
}

export default SettingsReviewer
