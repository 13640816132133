import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectSheet } from '../common/store/app'
import { selectCurrentMapping } from '../common/store/mapping'
import { getPairingList } from '../common/util/mapping'
import ColumnCard from './ColumnCard'

import './SheetData.css'

const SheetData = ({ setFieldPairing, setOutputNameColumn }) => {
  const currentMapping = useSelector(selectCurrentMapping)
  const sheet = useSelector(selectSheet)

  const [currentHoverField, setCurrentHoverField] = useState(null)
  const [isCompatibleDataType, setIsCompatibleDataType] = useState(null)

  useEffect(() => {
    document.addEventListener('fieldhover', handleFieldHover)
    document.addEventListener('columndrop', handleColumnDrop)
    return () => {
      document.removeEventListener('fieldhover', handleFieldHover)
      document.removeEventListener('columndrop', handleColumnDrop)
    }
  }, [currentHoverField, isCompatibleDataType])

  const handleFieldHover = (event) => {
    setCurrentHoverField(event.detail.fieldType)
    setIsCompatibleDataType(event.detail.compatible)
  }

  const handleColumnDrop = (event) => {
    if (!currentHoverField) return

    if (isCompatibleDataType) {
      const columnFillSource = {
        type: 'column',
        value: event.detail.column.title,
        columnId: event.detail.column.id || event.detail.column.virtualId
      }

      if (currentHoverField === 'outputNameMapping') {
        setOutputNameColumn(columnFillSource)
      } else {
        setFieldPairing(currentHoverField, columnFillSource)
      }
    }

    setCurrentHoverField(null)
    setIsCompatibleDataType(null)
  }

  const columns = sheet?.columns || []
  const pairingList = getPairingList(currentMapping).filter(
    (pairing) => pairing.type !== 'constant'
  )

  const isPaired = (column) =>
    pairingList.find(
      (pairing) =>
        pairing.columnId === column.id || pairing.columnId === column.virtualId
    )

  return (
    <div className='card-list'>
      {columns.map((col) => (
        <div
          key={col.id ?? col.virtualId}
          className='card-slot'
          data-testid='column-card'
        >
          <ColumnCard
            key={col.id ?? col.virtualId}
            isPaired={isPaired(col)}
            column={col}
          />
        </div>
      ))}
    </div>
  )
}

SheetData.propTypes = {
  setFieldPairing: PropTypes.func.isRequired,
  setOutputNameColumn: PropTypes.func.isRequired
}

export default SheetData
