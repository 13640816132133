import { Select } from '@smartsheet/lodestar-core'
import { ArrowRightIcon } from '@smartsheet/lodestar-icons'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  getPairedOptions,
  getStaticOptions,
  isContactColumn,
  isStaticRecipient
} from './helpers'

import './RecipientRow.css'

const RecipientRow = ({ pairing, recipient, update, options }) => {
  const { t } = useTranslation()

  const [selectedNameOption, setSelectedNameOption] = useState(null)
  const [selectedEmailOption, setSelectedEmailOption] = useState(null)

  const isStatic = isStaticRecipient(recipient)

  useEffect(() => {
    if (isStatic) {
      const { nameOption, emailOption } = getStaticOptions(recipient, t)
      setSelectedNameOption(nameOption)
      setSelectedEmailOption(emailOption)
    } else {
      const { nameOption, emailOption } = getPairedOptions(pairing, options)
      setSelectedNameOption(nameOption)
      setSelectedEmailOption(emailOption)
    }
  }, [])

  useEffect(() => {
    if (!isStatic) {
      savePairedOptions(selectedNameOption, selectedEmailOption)
    }
  }, [selectedNameOption, selectedEmailOption])

  // sets the current paired name and email options for the role
  const savePairedOptions = (name, email) => {
    // if user data comes from smartsheet contact column
    if (isContactColumn(name)) {
      return updatePairing(recipient.recipientId, {
        contactColumnId: name.value
      })
    }
    // if user data comes from smartsheet name/email columns
    if (name && email) {
      return updatePairing(recipient.recipientId, {
        nameColumnId: name.value,
        emailColumnId: email.value
      })
    }
  }

  const updatePairing = (recipientId, pairing) =>
    update({
      [recipientId]: pairing
    })

  const isInPersonSigner = recipient?.recipientType === 'inpersonsigner'

  return (
    <>
      <div>
        <b>{recipient.roleName}</b>
        <br />
        <span className='recipient-type'>
          {recipient
            ? t('wizard.screens.docusign.roleAssigner.type', {
                context: recipient.recipientType
              })
            : ''}
        </span>
      </div>
      <div className='right-arrow-container'>
        <ArrowRightIcon size='large' color='#979797' />
      </div>
      <div>
        {/* TODO: UPGRADE TO SELECT v2 */}
        <Select
          isClearable={false}
          isDisabled={isStatic}
          className='recipient-dropdown'
          value={selectedNameOption}
          options={isStatic ? [selectedNameOption] : options}
          placeholder={t(
            'wizard.screens.docusign.roleAssigner.namePlaceholder'
          )}
          onChange={setSelectedNameOption}
          menuPortalTarget={document.getElementById(
            'wizard-role-assigner-select-container'
          )}
          aria-label={`${t(
            'wizard.screens.docusign.roleAssigner.namePlaceholder'
          )} - ${recipient.roleName}`}
        />
      </div>
      <div>
        {/* TODO: UPGRADE TO SELECT v2 */}
        <Select
          isClearable={false}
          isDisabled={isStatic || isContactColumn(selectedNameOption)}
          className='recipient-dropdown'
          value={
            !isContactColumn(selectedNameOption) ? selectedEmailOption : null
          }
          options={
            isStatic
              ? [selectedEmailOption]
              : options.filter((option) => !isContactColumn(option))
          }
          placeholder={t(
            isInPersonSigner
              ? 'wizard.screens.docusign.roleAssigner.hostEmailPlaceHolder'
              : 'wizard.screens.docusign.roleAssigner.emailPlaceholder'
          )}
          onChange={setSelectedEmailOption}
          menuPortalTarget={document.getElementById(
            'wizard-role-assigner-select-container'
          )}
          aria-label={`${t(
            isInPersonSigner
              ? 'wizard.screens.docusign.roleAssigner.hostEmailPlaceHolder'
              : 'wizard.screens.docusign.roleAssigner.emailPlaceholder'
          )} - ${recipient.roleName}`}
        />
      </div>
    </>
  )
}

RecipientRow.propTypes = {
  pairing: PropTypes.object,
  recipient: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired
}

export default RecipientRow
