import {
  StandardLoggerContext,
  UpgradeTooltip
} from '@smartsheet/growth-components'
import {
  UpsellExperience,
  UserType
} from '@smartsheet/growth-components/dist/Upgrade/Licensing'
import { useFlyout } from '@smartsheet/lodestar-core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UpsellIcon } from './icon/BasicIcons'

import './UpsellWrapper.css'

const salesEmail = 'sales@smartsheet.com'
const objectIdWizardUpsell = 2191
const objectIdMappingsListUpsell = 2192

const contactUsURL = 'https://www.smartsheet.com/contact/sales'
const ftsParameter = 'in-app-docusign-upgrade'

const logEventWithoutPrivateInfo = (objectID, actionID) => {
  var objectName

  switch (objectID) {
    // Names and object IDs strings come from app-core
    // https://git.lab.smartsheet.com/team-core-dev/app-core/-/merge_requests/15753/diffs
    case objectIdWizardUpsell:
      objectName = 'Upgrade - DocuSign Wizard Tooltip'
      break
    case objectIdMappingsListUpsell:
      objectName = 'Upgrade - DocuSign Mappings List'
      break
    default:
      break
  }
  window.clientLogger.bi(objectID, objectName, actionID)
}

export const DocuSignUpsellWrapper = ({ hardOpen, close, context }) => {
  const { t } = useTranslation()
  const [isOpenState, setIsOpenState] = useState(false)
  const [hovering, setHovering] = useState(false)
  const offset = {
    distance: 0,
    skidding: 0
  }
  let objectId = 0
  // Offset and the objectId needs to differ between the two contexts
  // where the upsell wrapper is used.
  if (context === 'wizard') {
    objectId = objectIdWizardUpsell
  } else if (context === 'mapping-list') {
    objectId = objectIdMappingsListUpsell
  }

  if (hardOpen && !isOpenState) setIsOpenState(true)
  if (hovering && !isOpenState) setIsOpenState(true)
  if (!hovering && isOpenState && !hardOpen) setIsOpenState(false)

  const { flyoutProps, targetProps } = useFlyout({
    isOpen: isOpenState,
    id: 'wizard-upsell-flyout',
    onCloseRequested: () => {
      setIsOpenState(false)
      close()
    },
    portalProps: {
      zIndex: 700
    },
    placement: 'top',
    offset
  })

  const userTypeExperienceMap = {
    [UserType.FREE_COLLAB]: UpsellExperience.CONTACT_TO_USE
  }

  return (
    <div
      className='upsell-wrapper'
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => {
        setHovering(false)
        // UpgradeTooltip component doesn't handle the close event
        // when we stop hovering, so we'll need to do it ourselves
        logEventWithoutPrivateInfo(objectId, 2)
      }}
    >
      <div {...targetProps}>
        <UpsellIcon />
      </div>
      <StandardLoggerContext.Provider value={{ logEventWithoutPrivateInfo }}>
        <UpgradeTooltip
          buttonText={t('upsells.docusign.button')}
          contactUsTitle={t('upsells.docusign.title')}
          contactUsBody={
            <>
              <p>{t('upsells.docusign.body')}</p>
              <div>
                <b>{t('upsells.docusign.phoneLabel')}</b>{' '}
                {t('upsells.docusign.phoneNumbers')}
              </div>
              <div>
                <b>{t('upsells.docusign.emailLabel')}</b>
                <a
                  href={`mailto: ${salesEmail}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {' '}
                  {salesEmail}
                </a>
              </div>
            </>
          }
          flyoutProps={flyoutProps}
          logClickButtonObjectId={objectId}
          logCloseObjectId={objectId}
          logOpenObjectId={objectId}
          onClick={() =>
            window.open(`${contactUsURL}?fts=${ftsParameter}`, '_blank')
          }
          onClose={() => {
            setIsOpenState(false)
            setHovering(false)
            close()
          }}
          orgId={1}
          overlayEnabled={hardOpen}
          planId={UserType.FREE_COLLAB}
          userTypeExperienceMapping={userTypeExperienceMap}
        />
      </StandardLoggerContext.Provider>
    </div>
  )
}
