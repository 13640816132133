import { datadogRum } from '@datadog/browser-rum'

const setDataDogUser = (user) => {
  datadogRum.setUser({
    id: user.id,
    locale: user.locale,
    admin: user.admin,
    account: {
      id: user.account.id,
      plan: user.account.plan || ''
    }
  })
}

export default {
  setDataDogUser
}
