import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import isPlainObject from 'lodash/isPlainObject'
import pick from 'lodash/pick'

import {
  fetchReportData,
  fetchSheetData,
  getCurrentUser as getCurrentUserDB,
  getMaskedId
} from '../SmartfillApi'
import { loadFeatures } from '../util/features'

const initialState = {
  user: null,
  featureEnabledMap: {},
  env: window?.applicationConfig?.appEnvironment || 'prod',
  smartsheetObject: null,
  sheet: null
}

export const fetchAppData = createAsyncThunk(
  'app/fetchAppData',
  async (searchParams) => {
    const { user } = await getCurrentUser()
    const featureEnabledMap = await loadFeatures()
    const { smartsheetObject, sheet } = await getSheetData(searchParams)

    return {
      user,
      featureEnabledMap,
      smartsheetObject,
      sheet
    }
  }
)

const getCurrentUser = async () => {
  const { data: user } = await getCurrentUserDB()

  return {
    user: {
      // Return only user props needed.
      ...pick(user, [
        'firstName',
        'lastName',
        'email',
        'locale',
        'id',
        'admin'
      ]),
      account: pick(user, ['id', 'plan'])
    }
  }
}

const getSheetData = async (searchParams) => {
  const objectId = searchParams.get('objectId')
  const rowIds = searchParams.get('rowIds')?.split(',').map(Number)

  const { data: smartsheetObject } = await getMaskedId(objectId)

  // Return only sheet props needed.
  const filterSheet = (sheet) => {
    return {
      ...pick(sheet, ['accessLevel', 'sourceSheets', 'permalink', 'name']),
      rows: sheet.rows.map((row) => pick(row, ['id', 'accessLevel'])),
      columns: sheet.columns.map((column) =>
        pick(column, ['id', 'title', 'systemColumnType', 'type', 'virtualId'])
      )
    }
  }

  if (smartsheetObject.type === 'sheet') {
    let { data: sheet } = await fetchSheetData(smartsheetObject.id, rowIds)
    sheet = filterSheet(sheet)

    return {
      smartsheetObject,
      sheet
    }
  } else if (smartsheetObject.type === 'report') {
    let { data: sheet } = await fetchReportData(smartsheetObject.id)
    sheet = filterSheet(sheet)

    if (rowIds) {
      return {
        smartsheetObject,
        sheet: {
          ...sheet,
          rows: sheet.rows.filter((row) => rowIds.includes(row.id))
        }
      }
    } else if (!rowIds) {
      return {
        smartsheetObject,
        sheet
      }
    }
  }
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateSheet: (state, { payload: sheet }) => {
      if (!state.sheet) return
      if (!isPlainObject(sheet)) return

      const updatedSheet = {
        ...state.sheet,
        ...sheet
      }

      state.sheet = updatedSheet
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAppData.fulfilled, (state, { payload }) => {
      state.user = payload.user
      state.featureEnabledMap = payload.featureEnabledMap
      state.smartsheetObject = payload.smartsheetObject
      state.sheet = payload.sheet
    })
  }
})

export const selectUser = (state) => state.app.user

export const selectFeatureEnabledMap = (state) => state.app.featureEnabledMap

export const selectEnv = (state) => state.app.env

export const selectSheet = (state) => state.app.sheet

export const selectSheetRowIds = (state) => {
  const sheet = selectSheet(state)

  if (sheet && sheet.rows) {
    return sheet.rows.map((row) => row.id)
  }

  return []
}

export const selectSmartsheetObject = (state) => state.app.smartsheetObject

export const { updateSheet } = appSlice.actions

export default appSlice.reducer
