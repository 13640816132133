import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import PageFooter from './PageFooter'
import ErrorModal from './common/ErrorModal'
import PageHeader from './common/PageHeader'
import {
  selectCurrentMapping,
  selectIsCurrentMappingChanged,
  selectMappingList
} from './common/store/mapping'
import Editor from './editor/Editor'
import MappingsList from './mappings-list/MappingsList'
import NoMappings from './no-mappings/NoMappings'
import Wizard from './wizard/Wizard'

import './Mapper.css'

const Mapper = () => {
  const mappingList = useSelector(selectMappingList)
  const currentMapping = useSelector(selectCurrentMapping)
  const isCurrentMappingChanged = useSelector(selectIsCurrentMappingChanged)

  const [isWizardOpen, setIsWizardOpen] = useState(false)
  const [isEditorOpen, setIsEditorOpen] = useState(false)

  // primes the 'leaving site?' dialogue if there are unsaved changes
  useEffect(() => {
    if (isCurrentMappingChanged) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = null
    }
  }, [isCurrentMappingChanged])

  const isBeingEdited = !!currentMapping

  return (
    <div className='mapper-wrapper'>
      <PageHeader
        setIsWizardOpen={setIsWizardOpen}
        setIsEditorOpen={setIsEditorOpen}
      />
      <div className='mapper-main' role='main'>
        {isEditorOpen && isBeingEdited ? (
          <Editor
            setIsWizardOpen={setIsWizardOpen}
            setIsEditorOpen={setIsEditorOpen}
          />
        ) : mappingList?.length ? (
          <MappingsList
            setIsWizardOpen={setIsWizardOpen}
            setIsEditorOpen={setIsEditorOpen}
          />
        ) : (
          <NoMappings setIsWizardOpen={setIsWizardOpen} />
        )}
      </div>
      {isWizardOpen && isBeingEdited && (
        <Wizard
          setIsWizardOpen={setIsWizardOpen}
          setIsEditorOpen={setIsEditorOpen}
        />
      )}
      <PageFooter />
      <ErrorModal />
    </div>
  )
}

export default Mapper
