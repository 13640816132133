import React from 'react'
import { useSelector } from 'react-redux'

import NotificationRibbon from './NotificationRibbon'
import { selectSheetRowIds, selectSmartsheetObject } from './store/app'
import { rowLimit } from './util/constants'

const RowCountBanner = () => {
  const smartsheetObject = useSelector(selectSmartsheetObject)
  const rowIds = useSelector(selectSheetRowIds)
  const rowCount = rowIds.length

  const getMessageKey = () => {
    if (!smartsheetObject?.type) {
      return ''
    }

    if (rowCount === 0) {
      return smartsheetObject.type === 'report'
        ? 'ribbon.noRows_report'
        : 'ribbon.noRows'
    }

    if (rowCount > rowLimit) {
      return smartsheetObject.type === 'report'
        ? 'ribbon.tooManyRows_report'
        : 'ribbon.tooManyRows'
    }

    return ''
  }

  const messageKey = getMessageKey()

  return messageKey ? <NotificationRibbon messageKey={messageKey} /> : <></>
}

export default RowCountBanner
