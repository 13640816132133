import { combineReducers, configureStore, createAction } from '@reduxjs/toolkit'

import app from './app'
import batchJobStatus from './batchJobStatus'
import docusign from './docusign'
import error from './error'
import mapping from './mapping'

const combinedReducer = combineReducers({
  app,
  docusign,
  error,
  mapping,
  batchJobStatus
})

export const reducer = (state, action) => {
  if (action.type === 'store/resetStore') {
    state = undefined // reset entire store to initial state
  }
  return combinedReducer(state, action)
}

export const store = configureStore({ reducer })

export const resetStore = createAction('store/resetStore')
