import React from 'react'

import { AlertIcon } from './icon/BasicIcons'

import './AlertBar.css'

const AlertBar = (props) => {
  return (
    <div className='alert-bar'>
      <AlertIcon />
      <div>{props.children}</div>
    </div>
  )
}

export default AlertBar
