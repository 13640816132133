import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  TextArea,
  TextInput
} from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { previewDocuSignEmails } from '../common/SmartfillApi'
import { selectSheetRowIds, selectSmartsheetObject } from '../common/store/app'
import { setError } from '../common/store/error'
import { selectCurrentMapping } from '../common/store/mapping'

import './EmailPreviewer.css'

const EmailPreview = ({ header, subject, body, labelID }) => {
  const { t } = useTranslation()

  return (
    <div className='email-preview'>
      <h3>{header}</h3>
      <h4 id={labelID + 'msgSubject'}>
        {t('emailPreviewer.msgSubjectHeader')}
      </h4>
      <TextInput
        className='email-subject'
        value={subject}
        onChange={() => {}}
        isDisabled={true}
        aria-labelledby={labelID + 'msgSubject'}
      />
      <h4 id={labelID + 'msgBody'}>{t('emailPreviewer.msgBodyHeader')}</h4>
      {body ? (
        <TextArea
          value={body}
          isDisabled={true}
          className='email-body'
          aria-labelledby={labelID + 'msgBody'}
        />
      ) : (
        <p data-testid='email-preview-body-empty'>
          {t('emailPreviewer.empty')}
        </p>
      )}
    </div>
  )
}
EmailPreview.propTypes = {
  header: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  labelID: PropTypes.string.isRequired
}

const EmailPreviewer = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const smartsheetObject = useSelector(selectSmartsheetObject)
  const currentMapping = useSelector(selectCurrentMapping)
  const rowIds = useSelector(selectSheetRowIds)

  const [isLoading, setIsLoading] = useState(false)
  const [rolePreviews, setRolePreviews] = useState([])
  const [templatePreview, setTemplatePreview] = useState(null)

  useEffect(() => {
    loadEmailPreviews()
  }, [])

  const loadEmailPreviews = () => {
    if (!currentMapping.docusign || !rowIds.length) return

    setIsLoading(true)
    previewDocuSignEmails(
      currentMapping.docusign.templateId,
      rowIds[0],
      smartsheetObject
    )
      .then((resp) => {
        setTemplatePreview(resp.data.templatePreview)
        setRolePreviews(resp.data.rolePreviews || [])
      })
      .catch((error) => {
        setIsOpen(false)
        dispatch(setError(error))
      })
      .finally(() => setIsLoading(false))
  }

  const isCustomMessageSet =
    templatePreview?.originalSubject ||
    templatePreview?.originalBody ||
    rolePreviews?.length

  return (
    <Modal
      className='email-previewer'
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      onCloseRequested={() => setIsOpen(false)}
    >
      <ModalHeader
        title={t('emailPreviewer.header')}
        onCloseRequested={() => setIsOpen(false)}
      />
      <ModalBody aria-live='polite' aria-relevant='additions text'>
        {isLoading && <Spinner size='medium' className='spinner' />}
        {!isLoading && isCustomMessageSet && (
          <div className='email-previews'>
            <div className='template-msg-preview'>
              <EmailPreview
                header={t('emailPreviewer.templateHeader')}
                subject={templatePreview.filledSubject}
                body={templatePreview.filledBody}
                labelID={'CustomMessageSet'}
              />
            </div>
            <div className='role-msg-previews'>
              {!!rolePreviews?.length &&
                rolePreviews.map((preview) => (
                  <EmailPreview
                    header={preview.roleName}
                    className='role-message'
                    key={preview.roleName || 'template'}
                    subject={preview.filledSubject}
                    body={preview.filledBody}
                    labelID={preview.roleName.replace(' ', '_') || 'template'}
                  />
                ))}
            </div>
          </div>
        )}
        {!isLoading && !isCustomMessageSet && t('emailPreviewer.empty')}
      </ModalBody>
      <ModalFooter />
    </Modal>
  )
}
EmailPreviewer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
}

export default EmailPreviewer
