import React from 'react'

export const MappingIconPDF = () => (
  <i>
    <svg viewBox='0 0 86 86'>
      <title>PDF Mapping</title>
      <g
        className='PDF'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <rect fill='#D8E4FA' x='0' y='0' width='86' height='86'></rect>
        <path
          d='M22,15 L64,15 C65.1045695,15 66,15.8954305 66,17 L66,69 C66,70.1045695 65.1045695,71 64,71 L22,71 C20.8954305,71 20,70.1045695 20,69 L20,17 C20,15.8954305 20.8954305,15 22,15 Z'
          className='Rectangle'
          fill='#FFFFFF'
        ></path>
        <path
          d='M66,14 C66.5522847,14 67,14.4477153 67,15 L67,71 C67,71.5522847 66.5522847,72 66,72 L20,72 C19.4477153,72 19,71.5522847 19,71 L19,15 C19,14.4477153 19.4477153,14 20,14 L66,14 Z M65.5666667,15.5 L20.5,15.5 L20.5,70.5 L65.5666667,70.5 L65.5666667,15.5 Z'
          className='Combined-Shape'
          fill='#004173'
        ></path>
        <rect
          className='Rectangle'
          fill='#A7C1E4'
          x='28'
          y='23'
          width='30'
          height='1.5'
          rx='0.75'
        ></rect>
        <rect
          className='Rectangle-Copy-2'
          fill='#A7C1E4'
          x='28'
          y='55'
          width='30'
          height='1.5'
          rx='0.75'
        ></rect>
        <rect
          className='Rectangle-Copy'
          fill='#A7C1E4'
          x='28'
          y='31'
          width='30'
          height='1.5'
          rx='0.75'
        ></rect>
        <rect
          className='Rectangle-Copy-3'
          fill='#A7C1E4'
          x='28'
          y='63'
          width='30'
          height='1.5'
          rx='0.75'
        ></rect>
        <path
          d='M32.8414322,48.5 L32.8414322,45.5428571 L34.6214834,45.5428571 C36.4629156,45.5428571 37.6905371,44.2571429 37.6905371,42.5214286 C37.6905371,40.7857143 36.4629156,39.5 34.6214834,39.5 L31,39.5 L31,48.5 L32.8414322,48.5 Z M34.5601023,43.7428571 L32.8414322,43.7428571 L32.8414322,41.3 L34.5601023,41.3 C35.2966752,41.3 35.7877238,41.8142857 35.7877238,42.5214286 C35.7877238,43.2285714 35.2966752,43.7428571 34.5601023,43.7428571 Z M42.4782609,48.5 C45.1176471,48.5 47.0204604,46.5071429 47.0204604,44 C47.0204604,41.4928571 45.1176471,39.5 42.4782609,39.5 L39.3478261,39.5 L39.3478261,48.5 L42.4782609,48.5 Z M42.4782609,46.7 L41.1892583,46.7 L41.1892583,41.3 L42.4782609,41.3 C44.0127877,41.3 45.1176471,42.4571429 45.1176471,44 C45.1176471,45.5428571 44.0127877,46.7 42.4782609,46.7 Z M50.5191816,48.5 L50.5191816,45.2214286 L54.0179028,45.2214286 L54.0179028,43.4214286 L50.5191816,43.4214286 L50.5191816,41.3 L55,41.3 L55,39.5 L48.6777494,39.5 L48.6777494,48.5 L50.5191816,48.5 Z'
          fill='#004173'
          fillRule='nonzero'
        ></path>
      </g>
    </svg>
  </i>
)

export const MappingIconDocuSign = () => (
  <i>
    <svg viewBox='0 0 86 86'>
      <title>DocuSign Mapping</title>
      <g
        className='E-Sign'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <rect fill='#D8E4FA' x='0' y='0' width='86' height='86'></rect>
        <path
          d='M22,15 L64,15 C65.1045695,15 66,15.8954305 66,17 L66,69 C66,70.1045695 65.1045695,71 64,71 L22,71 C20.8954305,71 20,70.1045695 20,69 L20,17 C20,15.8954305 20.8954305,15 22,15 Z'
          className='Rectangle'
          fill='#FFFFFF'
        ></path>
        <path
          d='M66,14 C66.5522847,14 67,14.4477153 67,15 L67,71 C67,71.5522847 66.5522847,72 66,72 L20,72 C19.4477153,72 19,71.5522847 19,71 L19,15 C19,14.4477153 19.4477153,14 20,14 L66,14 Z M65.5666667,15.5 L20.5,15.5 L20.5,70.5 L65.5666667,70.5 L65.5666667,15.5 Z'
          className='Combined-Shape'
          fill='#004173'
        ></path>
        <rect
          className='Rectangle-Copy-5'
          fill='#A7C1E4'
          x='28'
          y='23'
          width='30'
          height='1.5'
          rx='0.75'
        ></rect>
        <rect
          className='Rectangle-Copy-6'
          fill='#A7C1E4'
          x='28'
          y='31'
          width='30'
          height='1.5'
          rx='0.75'
        ></rect>
        <rect
          className='Rectangle-Copy-7'
          fill='#A7C1E4'
          x='28'
          y='39'
          width='30'
          height='1.5'
          rx='0.75'
        ></rect>
        <line
          x1='36.5'
          y1='62.5'
          x2='59.5'
          y2='62.5'
          className='Line-2'
          stroke='#A7C1E4'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='1,3'
        ></line>
        <path
          d='M36.7134234,57.5159308 C52.7989378,38.061261 39.4911858,55.3168094 41.7657414,56.1950353 C44.040297,57.0732613 45.7213275,51.0176922 47.0524191,51.8128712 C48.3835107,52.6080501 45.7525128,55.8705485 46.754519,56.2568584 C47.7565253,56.6431682 49.204727,54.0040528 50.3572141,54.1604126 C51.5097011,54.3167725 49.8009066,56.1701344 51.5563964,56.8430326 C53.3118863,57.5159308 56.0900723,55.4043642 57.6946251,53.4610821'
          className='Path-2'
          stroke='#004173'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M24.5974897,49.75 L29.6928792,54.548276 L24.7893956,59.1883613 L12,59.25 L11.75,50 L24.5974897,49.75 Z'
          className='Rectangle'
          stroke='#5996D6'
          strokeWidth='1.5'
          fill='#5996D6'
        ></path>
      </g>
    </svg>
  </i>
)
