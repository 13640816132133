import { ModalFooterButton } from '@smartsheet/lodestar-core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { selectSmartsheetObject } from '../common/store/app'
import { fetchDocusignAuth } from '../common/store/docusign'
import { setError } from '../common/store/error'
import { docuSignAuth } from './dsAuthUtil'

const DocuSignAuthRetry = ({ closeModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const smartsheetObject = useSelector(selectSmartsheetObject)

  const [isLoading, setIsLoading] = useState(false)

  const handleLogin = () => {
    setIsLoading(true)
    docuSignAuth(smartsheetObject)
      .then(() => dispatch(fetchDocusignAuth(smartsheetObject)).unwrap())
      .catch((error) => dispatch(setError(error)))
      .finally(() => closeModal())
  }

  return (
    <ModalFooterButton
      appearance={'primary'}
      data-dd-action-name={'smar:wizard.docusign.auth.login.btn'}
      onClick={handleLogin}
      isLoading={isLoading}
    >
      {t('wizard.screens.docusign.auth.button', {
        context: 'login'
      })}
    </ModalFooterButton>
  )
}

export default DocuSignAuthRetry
