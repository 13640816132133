import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const MappingCountBadge = ({ shown, total }) => {
  const { t } = useTranslation()

  let msg
  if (shown !== total) {
    msg = t('sortAndFilter.mappingQuantityMsg.partial')
      .replace('<qty>', shown)
      .replace('<total>', total)
  } else if (total === 1) {
    msg = t('sortAndFilter.mappingQuantityMsg.singular')
  } else {
    msg = t('sortAndFilter.mappingQuantityMsg.plural').replace('<qty>', shown)
  }

  return (
    <p className='mapping-count-badge' style={{ color: '#767676' }}>
      {msg}
    </p>
  )
}

MappingCountBadge.protoTypes = {
  shown: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

export default MappingCountBadge
