import { Button } from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import RowCountBanner from '../common/RowCountBanner'
import { selectSheet, selectSmartsheetObject } from '../common/store/app'
import { setCurrentMapping } from '../common/store/mapping'
import { userCanCreateMappings } from '../common/util/permissions'
import BlankStateImage from './dbBlankIllustration.png'

import './NoMappings.css'

const NoMappings = ({ setIsWizardOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const sheet = useSelector(selectSheet)
  const smartsheetObject = useSelector(selectSmartsheetObject)

  const handleCreateMapping = () => {
    // Create a mapping object in a "new" state and set as the current mapping.
    dispatch(setCurrentMapping({ smartsheetObject }))
    setIsWizardOpen(true)
  }

  return (
    <div className='no-mappings'>
      <RowCountBanner />
      <div className='centerpiece'>
        <div className='nice-image'>
          <img
            src={BlankStateImage}
            alt={t('mappingsTable.blankStateImageAlt')}
          />
        </div>
        <div className='title'>{t('mappingsTable.blankState.header')}</div>
        <div className='subtitle'>
          {t('mappingsTable.blankState.subheader')}
        </div>
        <Button
          className='button-create'
          data-dd-action-name='smar:mapping.list.create.btn'
          isDisabled={!userCanCreateMappings(sheet)}
          onClick={handleCreateMapping}
        >
          {t('mappingsTable.blankState.button')}
        </Button>
      </div>
    </div>
  )
}

NoMappings.protoTypes = {
  setIsWizardOpen: PropTypes.func.isRequired
}

export default NoMappings
