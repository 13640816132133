import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

import { getJobStatus } from '../SmartfillApi'

export const BATCH_JOB_TYPE = {
  downloads: 'Download',
  attachments: 'Attachments',
  docuSignEnvelopes: 'DocuSignEnvelopes',
  docuSignEnvelopeDrafts: 'DocuSignEnvelopeDrafts'
}

export const BATCH_JOB_STATUS_TEMPLATE = {
  isUpdateComplete: false, // Flag to reduce server call checks.
  isUpdateStarted: false, // Flag stating if updated by server yet.
  itemsComplete: 0,
  itemsFailed: 0,
  itemsTotal: 0,
  errors: null,
  fileName: null,
  filePath: null
}

const initialState = {
  batchJobStatusMap: {}
}

export const refreshBatchJobStatus = createAsyncThunk(
  'batchJobStatus/refreshBatchJobStatus',
  async (batchJobStatusId, { getState, dispatch }) => {
    const currentBatchJobStatus = selectBatchJobStatus(batchJobStatusId)(
      getState()
    )
    let batchJobStatus = null

    try {
      batchJobStatus = (await getJobStatus(batchJobStatusId)).data
    } catch (error) {
      if (error.response.status === 404) {
        // If the job is not found on backend remove it from state.
        dispatch(deleteBatchJobStatus({ batchJobStatusId }))
      } else {
        // If here job status called failed completely so report it.
        throw error
      }
    }

    let updatedBatchJobStatus = {
      ...currentBatchJobStatus,
      ...{
        isUpdateStarted: true,
        itemsComplete: batchJobStatus.ItemsComplete,
        itemsFailed: batchJobStatus.ItemsFailed,
        itemsTotal: batchJobStatus.ItemsTotal,
        errors: batchJobStatus.Errors,
        fileName: batchJobStatus.fileName,
        filePath: batchJobStatus.filePath
      }
    }

    if (
      batchJobStatus.ItemsComplete + batchJobStatus.ItemsFailed >=
      batchJobStatus.ItemsTotal
    ) {
      // If job found and complete, update complete status in state.
      // Completed jobs will be seen until page refresh.
      updatedBatchJobStatus.isUpdateComplete = true
    }

    if (!isEqual(currentBatchJobStatus, updatedBatchJobStatus)) {
      dispatch(updateBatchJobStatus({ batchJobStatus: updatedBatchJobStatus }))
    }
  }
)

const batchJobStatus = createSlice({
  name: 'batchJobStatus',
  initialState,
  reducers: {
    updateBatchJobStatus: (state, action) => {
      const batchJobStatus = action.payload.batchJobStatus
      state.batchJobStatusMap[batchJobStatus.id] = batchJobStatus
    },
    deleteBatchJobStatus: (state, action) => {
      const batchJobStatusId = action.payload.batchJobStatusId
      delete state.batchJobStatusMap[batchJobStatusId]
    }
  }
})

export const selectBatchJobStatus = (batchJobStatusId) => (state) => {
  return state.batchJobStatus.batchJobStatusMap[batchJobStatusId]
}
export const selectBatchJobStatusIdList = (state) => {
  return Object.keys(state.batchJobStatus.batchJobStatusMap)
}
export const selectDownloadCompleteBatchJobStatusList = (state) => {
  return Object.values(state.batchJobStatus.batchJobStatusMap).filter(
    (batchJobStatus) =>
      batchJobStatus.isUpdateComplete &&
      batchJobStatus.jobType === BATCH_JOB_TYPE.downloads
  )
}
export const selectIncompleteBatchJobStatusIdList = (state) => {
  return Object.values(state.batchJobStatus.batchJobStatusMap)
    .filter((batchJobStatus) => !batchJobStatus.isUpdateComplete)
    .map((batchJobStatus) => batchJobStatus.id)
}

export const { updateBatchJobStatus, deleteBatchJobStatus } =
  batchJobStatus.actions

export default batchJobStatus.reducer
