import { useEffect, useRef } from 'react'

const useInterval = (callback, delay) => {
  const callbackRef = useRef()

  // Remember the latest callback.
  useEffect(() => {
    if (typeof callback === 'function') {
      callbackRef.current = callback
    }
  }, [callback])

  // Set up the interval, will cancel interval if null delay passed.
  useEffect(() => {
    if (delay && typeof delay === 'number') {
      const id = setInterval(() => callbackRef.current(), delay)

      return () => clearInterval(id)
    }
  }, [delay])
}

export default useInterval
