import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import RowCountBanner from '../common/RowCountBanner'
import { selectSheet, selectUser } from '../common/store/app'
import { selectMappingList } from '../common/store/mapping'
import LogInModal from './LogInModal'
import MappingCountBadge from './MappingCountBadge'
import MappingRow from './MappingRow'
import SortAndFilterBar from './SortAndFilterBar'
import {
  defaultFilterOption,
  getMappingFilterFn,
  searchFilter
} from './mappingFilter'
import { defaultSortOption, getMappingSortFn } from './mappingSort'

import './MappingsList.css'

const MappingsList = ({ setIsEditorOpen }) => {
  const { t } = useTranslation()
  const user = useSelector(selectUser)
  const sheet = useSelector(selectSheet)
  const mappingList = useSelector(selectMappingList)

  const [sortOption, setSortOption] = useState(defaultSortOption)
  const [reverseSort, setReverseSort] = useState(sortOption.defaultReverseValue)
  const [filterOption, setFilterOption] = useState(defaultFilterOption)
  const [searchText, setSearchText] = useState('')
  const [modalMapping, setModalMapping] = useState(null)

  const onChangeSortOption = (option) => {
    setSortOption(option)
    setReverseSort(option.defaultReverseValue)
  }

  const toggleReverseSort = () => setReverseSort((prev) => !prev)

  const filteredMappings = mappingList
    .filter((mapping) => searchFilter(mapping.name, searchText, user.locale))
    .filter(getMappingFilterFn(filterOption))
    .sort(getMappingSortFn(sortOption, reverseSort, user.locale))

  return (
    <div className='mappings-list'>
      <div className='list-header'>
        <div className='heading-bar'>
          <div className='heading-group'>
            <h1>{t('mappingsTable.header', 'Saved Mappings')}</h1>
            <MappingCountBadge
              shown={filteredMappings.length}
              total={mappingList.length}
            />
          </div>
          <SortAndFilterBar
            sortOption={sortOption}
            reverseSort={reverseSort}
            filterOption={filterOption}
            searchText={searchText}
            onChangeSortOption={onChangeSortOption}
            toggleReverseSort={toggleReverseSort}
            onChangeFilterOption={setFilterOption}
            setSearchText={setSearchText}
          />
        </div>
      </div>
      <RowCountBanner />
      {filteredMappings.length ? (
        <div className='list-items' role='table'>
          {filteredMappings.map((mapping) => (
            <MappingRow
              key={mapping.id}
              mapping={mapping}
              rowCount={sheet.rows.length}
              searchText={searchText}
              sheet={sheet || {}}
              setIsEditorOpen={setIsEditorOpen}
              setModalMapping={setModalMapping}
            />
          ))}
        </div>
      ) : (
        <div className='no-results'>
          <h3>{t('sortAndFilter.noResults.heading')}</h3>
          <p>{t('sortAndFilter.noResults.message')}</p>
        </div>
      )}
      <LogInModal mapping={modalMapping} setModalMapping={setModalMapping} />
    </div>
  )
}

MappingsList.propTypes = {
  setIsEditorOpen: PropTypes.func.isRequired
}

export default MappingsList
