import { Tooltip, TooltipBody, useTooltip } from '@smartsheet/lodestar-core'
import { InfoIcon } from '@smartsheet/lodestar-icons'
import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'

const InfoTooltip = (data) => {
  const { t } = useTranslation()
  const { tooltipProps, targetProps } = useTooltip()
  return (
    <>
      <div className='tooltip-icon' {...targetProps}>
        <InfoIcon size='xSmall' />
      </div>
      <Tooltip {...tooltipProps} portalProps={{ zIndex: 800 }} placement='top'>
        <TooltipBody>{t(data.tooltipLocalization)}</TooltipBody>
      </Tooltip>
    </>
  )
}

export default withTranslation()(InfoTooltip)
