import moment from 'moment'

// TODO: replace moment
// import parseISO from 'date-fns/parseISO'

const genMappingSortFn = (option, locale) => {
  locale = locale.slice(0, 2)

  const mappingSortFns = {
    byCharCode: (a, b) =>
      b.name.localeCompare(a.name, locale, { sensitivity: 'base' }),
    byDateModified: (a, b) =>
      moment.utc(b.lastModified).diff(moment.utc(a.lastModified))
    // compareDesc(parseISO(a.lastModified), parseISO(b.lastModified))
  }

  return mappingSortFns[option.value]
}

export const getMappingSortFn = (option, reverse = false, locale) => {
  const fn = genMappingSortFn(option, locale)

  if (!fn) {
    throw new Error(`sort option is not valid`)
  }

  return reverse ? (a, b) => fn(b, a) : (a, b) => fn(a, b)
}

export const sortOptions = [
  {
    label: 'sortAndFilter.sortOptions.dateModified',
    labelSelected: 'sortAndFilter.sortOptions.dateModifiedSelected',
    value: 'byDateModified',
    defaultReverseValue: false
  },
  {
    label: 'sortAndFilter.sortOptions.name',
    labelSelected: 'sortAndFilter.sortOptions.nameSelected',
    value: 'byCharCode',
    defaultReverseValue: true
  }
]

export const defaultSortOption = sortOptions[0]
