import { Button, TypeRamp } from '@smartsheet/lodestar-core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import PageHeader from '../common/PageHeader'

import './Login.css'

const Login = () => {
  const { t } = useTranslation()

  const handleClick = (event) => {
    event.preventDefault()
    window.location.assign('/api/auth/login' + window.location.search)
  }

  return (
    <div>
      <PageHeader />
      <div className='login'>
        <div className='login--spacer' />
        <div className='login--content'>
          <TypeRamp variant='headerLarge'>{t('login.description')}</TypeRamp>
          <Button onClick={handleClick} data-dd-action-name='smar:login.btn'>
            {t('login.button')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login
