import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Mapper from '../Mapper'
import Callback from '../auth/Callback'
import DocuSignCallback from '../auth/DocuSignCallback'
import Login from '../auth/Login'

const Router = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Mapper />
      </Route>

      <Route exact path='/auth/callback'>
        <Callback />
      </Route>

      <Route exact path='/auth/callback/docusign'>
        <DocuSignCallback />
      </Route>

      <Route exact path='/login'>
        <Login />
      </Route>
    </Switch>
  )
}

export default Router
