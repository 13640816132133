import isEmpty from 'lodash/isEmpty'

// Utility for parsing server error responses.
// Follows https://github.com/axios/axios#handling-errors outline.
// Will return original error untouched if not a server error.
export function parseServerError(err) {
  let error = {}

  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    error.data = err.response.data.data || null
    error.code = +err.response.data.code || +err.status || 9000
    error.template = err.response.data.template || ''
  } else if (err.request) {
    // The request was made but no response was received
    // `err.request` is an instance of XMLHttpRequest
    error.code = +err.request.status
  } else if (err.data) {
    error.code = +err.data.code
  } else if (err.code) {
    error.code = +err.code
  }

  // If error.code exists but was actually not a number remove faulty prop/value.
  if (!Number.isInteger(error.code)) {
    delete error.code
  }

  return !isEmpty(error) ? error : err
}

export function getErrorDetails(t, error) {
  const errorDetails = {
    msg: '',
    showContactUs: false,
    showLogin: false
  }

  // If the error code is for a Smartsheet API error, use the text of the
  // original error. Otherwise, use a localized string. Smartsheet API errors
  // start at 1001, Smartfill errors are <= 1000
  if (error) {
    if (error.code && Number.isInteger(error.code) && error.code <= 1000) {
      // Allow i18n to return object values (showContactUs) from translation.json
      const info = t(
        [`errors.${error.code}`, 'errors.0'],
        'Something went wrong.',
        { ...error.data, returnObjects: true }
      )
      errorDetails.showContactUs = !!info['showContactUs']
      errorDetails.showLogin = !!info['showLogin']
      errorDetails.msg = typeof info === 'string' ? info : info['msg']
    } else if (error.template) {
      errorDetails.msg = error.template
    }
  }

  // Generic fallback error
  if (!errorDetails.msg) {
    const info = t('errors.0', 'Something went wrong.', { returnObjects: true })
    errorDetails.msg = typeof info === 'string' ? info : info['msg']
    errorDetails.showContactUs = true
  }

  return errorDetails
}
