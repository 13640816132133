// The maximum number of rows we want to allow a user to generate documents for
// at a time. Exceeding this count will display a clarifying message modal
// when a sheet is selected, and will disable the generate buttons.
export const rowLimit = 100

// FieldInfo FieldType values
export const FieldTypes = {
  MultiLineText: 'MultiLine',
  Text: 'Text',
  Checkbox: 'Button',
  Image: 'Image',
  Choice: 'Choice', // Picklists and dropdowns are both "choice"
  Unsupported: 'Unsupported'
}

export const columnTypes = {
  textNumber: 'TEXT_NUMBER',
  contactList: 'CONTACT_LIST',
  multiContactList: 'MULTI_CONTACT_LIST',
  picklist: 'PICKLIST',
  multiPicklist: 'MULTI_PICKLIST',
  date: 'DATE',
  datetime: 'DATETIME',
  abstractDatetime: 'ABSTRACT_DATETIME',
  duration: 'DURATION',
  predecessor: 'PREDECESSOR'
}
