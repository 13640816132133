import { Button, TypeRamp } from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './StepReviewRow.css'

const StepReviewRow = ({ stepName, content, editCommand }) => {
  const { t } = useTranslation()

  return (
    <div className='step-review-row'>
      <div className='copy-container'>
        <TypeRamp variant='headlineSmall' renderAs='h3'>
          {stepName}
        </TypeRamp>
        <p>{content}</p>
      </div>
      <Button
        appearance='secondary'
        onClick={editCommand}
        className='edit-button'
        data-dd-action-name='smar:wizard.settings.reviewer.edit.btn'
      >
        {t('wizard.screens.docusign.review.edit')}
      </Button>
    </div>
  )
}

StepReviewRow.propTypes = {
  stepName: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  editCommand: PropTypes.func
}

export default StepReviewRow
