import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterButton,
  ModalHeader
} from '@smartsheet/lodestar-core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AlertBar from '../common/AlertBar'
import DropZone from '../common/Dropzone'
import { getErrorDetails, parseServerError } from '../common/util/errors'
import { usePdfReplace } from './usePdfReplace'

const ReplaceModal = ({ replaceModalIsOpen, setReplaceModalIsOpen }) => {
  const { t } = useTranslation()

  const [isUploading, setIsUploading] = useState(false)
  const [isUploadComplete, setIsUploadComplete] = useState(false)
  const [error, setError] = useState(null)
  const { changeStats, replacePdf, revertChanges } = usePdfReplace()

  const upload = (file) => {
    setIsUploading(true)
    replacePdf(file)
      .then(() => setIsUploadComplete(true))
      .catch((error) => setError(parseServerError(error)))
      .finally(() => setIsUploading(false))
  }

  const revertAndClose = () => {
    revertChanges()
      .then(() => {
        setReplaceModalIsOpen(false)
      })
      .catch((error) => {
        setError(parseServerError(error))
      })
  }

  const errorDetails = error ? getErrorDetails(t, error) : {}

  return (
    <Modal
      isOpen={replaceModalIsOpen}
      onCloseRequested={() => setReplaceModalIsOpen(false)}
    >
      <ModalHeader
        title={t('replaceModal.title')}
        onCloseRequested={() => setReplaceModalIsOpen(false)}
      />

      {isUploadComplete ? (
        <ModalBody>
          <h2>{t('replaceModal.summary')}</h2>
          <ol>
            <li>
              {' '}
              {t('replaceModal.fieldsTransferred', {
                numberFields: changeStats?.updating
              })}
            </li>
            {!!changeStats?.additions && (
              <li>
                {t('replaceModal.fieldsAdded', {
                  numberFields: changeStats?.additions
                })}
              </li>
            )}
            {!!changeStats?.deletions && (
              <li>
                {t('replaceModal.fieldsRemoved', {
                  numberFields: changeStats?.deletions
                })}{' '}
              </li>
            )}
          </ol>
          <p>{t('replaceModal.replacementConfirmation')}</p>
          {error && <AlertBar>{errorDetails.msg}</AlertBar>}
        </ModalBody>
      ) : (
        <ModalBody>
          <DropZone uploading={isUploading} setFile={upload} />
          {error && <AlertBar>{errorDetails.msg}</AlertBar>}
        </ModalBody>
      )}

      <ModalFooter>
        <ModalFooterButton
          appearance={'secondary'}
          onClick={() =>
            isUploadComplete ? revertAndClose() : setReplaceModalIsOpen(false)
          }
        >
          {t('wizard.buttons.cancel')}
        </ModalFooterButton>
        <ModalFooterButton
          isDisabled={!isUploadComplete}
          onClick={() => setReplaceModalIsOpen(false)}
        >
          {t('wizard.buttons.done')}
        </ModalFooterButton>
      </ModalFooter>
    </Modal>
  )
}

export default ReplaceModal
