import React from 'react'

import BatchJobStatusModule from './batch-job-status/BatchJobStatusModule'

const PageFooter = () => {
  return (
    <footer>
      <BatchJobStatusModule />
    </footer>
  )
}

export default PageFooter
