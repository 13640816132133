import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterButton,
  ModalHeader
} from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { createStatusColumn } from '../common/SmartfillApi'
import {
  selectSheet,
  selectSmartsheetObject,
  updateSheet
} from '../common/store/app'
import { setError } from '../common/store/error'
import {
  clearCurrentMapping,
  fetchMappingList,
  selectCurrentMapping,
  updateCurrentMapping
} from '../common/store/mapping'
import { getMappingType } from '../common/util/mapping'
import DataSyncConfigurator from './data-sync-configurator/DataSyncConfigurator'
import DocuSignAuth from './docusign-auth/DocuSignAuth'
import MappingTypeSelector from './mapping-type-selector/MappingTypeSelector'
import RoleAssigner from './role-assigner/RoleAssigner'
import SettingsReviewer from './settings-reviewer/SettingsReviewer'
import StatusColumnConfigurator from './status-column-configurator/StatusColumnConfigurator'
import TemplateSelector from './template-selector/TemplateSelector'
import Uploader from './uploader/Uploader'

import './Wizard.css'

const Wizard = ({ setIsWizardOpen, setIsEditorOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const sheet = useSelector(selectSheet)
  const smartsheetObject = useSelector(selectSmartsheetObject)
  const currentMapping = useSelector(selectCurrentMapping)

  const isInitialized = useRef(
    !!(currentMapping.document || currentMapping.docusign)
  )

  const [currentStep, setCurrentStep] = useState(0)
  const [completedScreens, setCompletedScreens] = useState({})

  const cancelWizardSetup = () => {
    if (!isInitialized.current) {
      dispatch(clearCurrentMapping())
    }

    setIsWizardOpen(false)
  }

  const completeWizardSetup = async () => {
    if (!isInitialized.current) {
      setIsEditorOpen(true)
    }

    const statusColumn = sheet.columns.find(
      (col) => col.id === currentMapping.docusign?.statusColumnId
    )
    const columnName = currentMapping.temp?.statusColumnName

    if (!statusColumn && columnName) {
      try {
        const { data } = await createStatusColumn(smartsheetObject, columnName)
        dispatch(
          updateCurrentMapping({
            docusign: {
              ...currentMapping.docusign,
              statusColumnId: data.id
            },
            temp: {
              ...currentMapping.temp,
              statusColumnName: null
            }
          })
        )
        dispatch(
          updateSheet({
            columns: [
              {
                id: data.id,
                title: data.title,
                type: data.type
              },
              ...sheet.columns
            ]
          })
        )
        setIsWizardOpen(false)
      } catch (error) {
        dispatch(setError(error))
      }
    } else {
      setIsWizardOpen(false)
    }
  }

  const onDocuSignLogout = () =>
    dispatch(fetchMappingList(smartsheetObject))
      .unwrap()
      .then(() => {
        dispatch(clearCurrentMapping())
        setIsWizardOpen(false)
        setIsEditorOpen(false)
      })
      .catch((error) => {
        dispatch(setError(error))
      })

  useEffect(() => {
    if (isInitialized.current) {
      currentFlow.forEach((screen) => setComplete(screen, true))
      skipToLastStep()
    }
  }, [])

  const getCurrentFlow = () => {
    switch (getMappingType(currentMapping)) {
      // Flow for creating a "native" Mapping
      case 'native':
        return [screens.mappingTypeSelector, screens.native.pdfUploader]
      // Flow for importing a DocuSign template as a Mapping
      case 'docusign': {
        const gridType = smartsheetObject.type || null
        if (gridType === 'report') {
          // Omit the status column configurator when a report is selected
          return [
            screens.mappingTypeSelector,
            screens.docusign.auth,
            screens.docusign.templateSelector,
            screens.docusign.roleAssigner,
            screens.docusign.review
          ]
        } else {
          return [
            screens.mappingTypeSelector,
            screens.docusign.auth,
            screens.docusign.templateSelector,
            screens.docusign.roleAssigner,
            screens.docusign.statusColumnConfigurator,
            screens.docusign.dataSyncConfigurator,
            screens.docusign.review
          ]
        }
      }
      // Default flow (only used before Mapping Type has been set)
      default:
        return [screens.mappingTypeSelector]
    }
  }

  const changeScreen = (targetStep) => {
    // TODO: re-evaluate this function
    if (targetStep < 0) return cancelWizardSetup(false)

    // Do nothing if flow contains no screens
    if (!currentFlow.length) return
    // If targetStep is after end of the flow...
    if (targetStep > currentFlow.length - 1) {
      // Do nothing if the current step is incomplete
      if (!isComplete(currentScreen)) return
      // Exit wizard if the current step is complete
      return completeWizardSetup()
    }
    //
    if (targetStep < currentStep) {
      // Only allow user to go back to steps that have been completed
      // if (!isComplete(currentFlow[targetStep]))
      // actually, this is okay
    } else if (targetStep - currentStep > 1) {
      // Only allow jumping forward more than one step
      // if the step before the target has been completed
      if (!isComplete(currentFlow[targetStep - 1])) return
    } else {
      // Only allow proceeding to the next step if the current step
      // has been completed
      if (!isComplete(currentScreen)) return
    }

    //TODO: Needs to be a useEffect() to handle this state change??
    setCurrentStep(targetStep)
  }

  const skipToLastStep = () => setCurrentStep(currentFlow.length - 1)

  // Get a string representing the status of a particular screen
  const getScreenStatus = (screen) => {
    if (screen === currentScreen) return 'current'
    if (isComplete(screen)) return 'complete'
    return 'incomplete'
  }

  // Set whether all the tasks on a given screen have been completed
  const setComplete = (screen, isComplete) => {
    setCompletedScreens((prevState) => ({
      ...prevState,
      [screen.nameKey]: isComplete
    }))
  }

  // Get whether all the tasks on a given screen have been completed
  const isComplete = (screen) => {
    return !!completedScreens[screen.nameKey]
  }

  // check if all screens have been completed (except for review)
  const isAllComplete = () => {
    return currentFlow.reduce(
      (prev, curr) =>
        prev &&
        (!!completedScreens[curr.nameKey] ||
          curr.nameKey === screens.docusign.review.nameKey),
      true
    )
  }

  const screens = {
    mappingTypeSelector: {
      render: () => {
        return (
          <MappingTypeSelector
            isInitialized={isInitialized.current}
            setComplete={(isComplete) => {
              setCompletedScreens({})
              setComplete(screens.mappingTypeSelector, isComplete)
            }}
          />
        )
      },
      nameKey: 'wizard.screens.mappingType.stepName'
    },
    native: {
      pdfUploader: {
        render: () => {
          return (
            <Uploader
              isInitialized={isInitialized.current}
              setComplete={(isComplete) => {
                setComplete(screens.native.pdfUploader, isComplete)
                isComplete && completeWizardSetup(false)
              }}
            />
          )
        },
        nameKey: 'wizard.screens.native.uploader.stepName'
      }
    },
    docusign: {
      auth: {
        render: () => {
          return (
            <DocuSignAuth
              isInitialized={isInitialized.current}
              setComplete={(isComplete) =>
                setComplete(screens.docusign.auth, isComplete)
              }
              onDocuSignLogout={onDocuSignLogout}
            />
          )
        },
        nameKey: 'wizard.screens.docusign.auth.stepName'
      },
      templateSelector: {
        render: () => {
          return (
            <TemplateSelector
              isInitialized={isInitialized.current}
              setComplete={(isComplete) => {
                setComplete(screens.docusign.templateSelector, isComplete)
                setComplete(screens.docusign.roleAssigner, false)
                setComplete(screens.docusign.review, false)
              }}
            />
          )
        },
        nameKey: 'wizard.screens.docusign.templateSelector.stepName'
      },
      roleAssigner: {
        render: () => {
          return (
            <RoleAssigner
              isInitialized={isInitialized.current}
              setComplete={(isComplete) => {
                setComplete(screens.docusign.roleAssigner, isComplete)
              }}
            />
          )
        },
        nameKey: 'wizard.screens.docusign.roleAssigner.stepName'
      },
      statusColumnConfigurator: {
        render: () => {
          return (
            <StatusColumnConfigurator
              isInitialized={isInitialized.current}
              setComplete={(isComplete) =>
                setComplete(
                  screens.docusign.statusColumnConfigurator,
                  isComplete
                )
              }
            />
          )
        },
        nameKey: 'wizard.screens.docusign.statusColumnConfig.stepName'
      },
      dataSyncConfigurator: {
        render: () => {
          return (
            <DataSyncConfigurator
              isInitialized={isInitialized.current}
              setComplete={(isComplete) =>
                setComplete(screens.docusign.dataSyncConfigurator, isComplete)
              }
            />
          )
        },
        nameKey: 'wizard.screens.docusign.dataSyncConfig.stepName'
      },
      review: {
        render: () => {
          return (
            <SettingsReviewer
              isInitialized={isInitialized.current}
              setStep={changeScreen}
              setComplete={() =>
                setComplete(screens.docusign.review, isAllComplete())
              }
            />
          )
        },
        nameKey: 'wizard.screens.docusign.review.stepName'
      }
    }
  }

  const currentFlow = getCurrentFlow()
  const currentScreen = currentFlow[currentStep]

  return (
    currentScreen && (
      <Modal
        className='wizard'
        isOpen={true}
        onCloseRequested={() => cancelWizardSetup(false)}
        shouldCloseOnOverlayClick={false}
        width='63rem'
        height='45rem'
      >
        <div data-dd-action-name='smar:wizard.close.btn'>
          <ModalHeader
            className='modal-header'
            onCloseRequested={() => cancelWizardSetup(false)}
            title={
              <>
                {t('wizard.header.stepNumber', {
                  number: currentStep + 1
                })}
                <span className='step-name'>{t(currentScreen.nameKey)}</span>
              </>
            }
          />
        </div>
        <ModalBody className='modal-body'>
          <div className='body-container'>
            <div className='step-list'>
              <ul>
                {currentFlow.map((screen, index) => {
                  return (
                    <li
                      key={index}
                      className={`${getScreenStatus(screen)}`}
                      onClick={changeScreen.bind(this, index)}
                      data-dd-action-name={'smar:' + screen.nameKey}
                    >
                      <span
                        className={`step-name ${getScreenStatus(screen)}`}
                        aria-label={t('wizard.screens.ariaInstructions')}
                      >
                        {t(screen.nameKey)}
                      </span>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className='primary-panel'>{currentScreen.render()}</div>
          </div>
        </ModalBody>
        <ModalFooter className='modal-footer'>
          <ModalFooterButton
            appearance={'secondary'}
            data-dd-action-name={
              'smar:wizard.' + (currentStep === 0 ? 'cancel' : 'back') + '.btn'
            }
            onClick={() => changeScreen(currentStep - 1)}
            shouldFitContainer={false}
          >
            {currentStep === 0
              ? t('wizard.buttons.cancel')
              : t('wizard.buttons.back')}
          </ModalFooterButton>
          <ModalFooterButton
            data-dd-action-name={
              'smar:wizard.' +
              (currentStep === currentFlow.length - 1 && currentFlow.length > 1
                ? 'done'
                : 'next') +
              '.btn'
            }
            onClick={() => changeScreen(currentStep + 1)}
            shouldFitContainer={false}
            isDisabled={!isComplete(currentScreen)}
          >
            {currentStep === currentFlow.length - 1 && currentFlow.length > 1
              ? t('wizard.buttons.done')
              : t('wizard.buttons.next')}
          </ModalFooterButton>
        </ModalFooter>
      </Modal>
    )
  )
}

Wizard.propTypes = {
  setIsWizardOpen: PropTypes.func.isRequired,
  setIsEditorOpen: PropTypes.func.isRequired
}

export default Wizard
