import React from 'react'

export const CheckboxIcon = () => (
  <i
    className='icon checkbox'
    style={{ width: '100%', height: '100%', margin: 0 }}
  >
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <g fill='none' fillRule='evenodd'>
        <path fill='#FFF' d='M6 6H26V26H6z' />
        <path
          fill='#161616'
          d='M26 4c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2H6c-1.105 0-2-.895-2-2V6c0-1.105.895-2 2-2h20zm0 2H6v20h20V6zm-4.186 4.518l.009.007.766.643c.42.352.477.976.13 1.4-4.837 5.899-7.264 8.855-7.279 8.87-.39.391-1.023.391-1.414 0l-4.38-4.38c-.361-.36-.389-.928-.084-1.32l.084-.094.707-.707c.39-.39 1.023-.39 1.414 0l2.818 2.818 5.822-7.098c.35-.427.98-.49 1.407-.14z'
        />
      </g>
    </svg>
  </i>
)

export const TextIcon = () => (
  <i className='icon text' style={{ width: '100%', height: '100%', margin: 0 }}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <path
        fill='#161616'
        fillRule='evenodd'
        d='M24 10L18 10 18 25 14 25 14 10 8 10 8 7 24 7z'
      />
    </svg>
  </i>
)

export const DateIcon = () => (
  <i className='icon date' style={{ width: '100%', height: '100%', margin: 0 }}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#161616'
          d='M28 6v20c0 1.105-.895 2-2 2H6c-1.105 0-2-.895-2-2V6c0-1.105.895-2 2-2h20c1.105 0 2 .895 2 2z'
        />
        <path
          fill='#FFF'
          fillRule='nonzero'
          d='M6 6h20v20H6V6zm6 6c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zm8 0c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM9.52 21.34c.069.792.441 1.527 1.04 2.05.596.52 1.348.78 2.254.78.958 0 1.758-.308 2.4-.928.644-.62.964-1.37.964-2.25.015-.562-.168-1.111-.516-1.552-.344-.428-.804-.704-1.378-.826.964-.526 1.444-1.226 1.444-2.106 0-.62-.236-1.176-.706-1.67-.568-.6-1.326-.9-2.268-.9-.552 0-1.05.104-1.494.31-.444.208-.79.492-1.04.856-.248.362-.434.846-.556 1.452l1.77.302c.05-.44.192-.772.424-1 .223-.223.527-.346.842-.34.297-.014.586.094.8.3.206.216.315.508.3.806.014.363-.137.713-.41.952-.274.24-.67.354-1.19.34l-.212 1.566c.342-.096.636-.144.882-.144.363-.004.71.15.95.424.26.282.39.666.39 1.148 0 .51-.136.916-.408 1.216-.25.291-.616.457-1 .452-.37 0-.684-.126-.944-.376-.258-.25-.418-.612-.478-1.086l-1.86.224zM20.062 24h1.92V13.938h-1.56c-.218.61-.622 1.144-1.21 1.602-.586.458-1.132.77-1.632.934v1.742c.952-.314 1.778-.8 2.482-1.456V24z'
        />
      </g>
    </svg>
  </i>
)

export const ContactsMultiIcon = () => (
  <i
    className='icon contacts-multi'
    style={{ width: '100%', height: '100%', margin: 0 }}
  >
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <path
        fill='#161616'
        fillRule='evenodd'
        d='M16 6c2.308 0 3.75 2.363 3.75 5 0 2.461-1.089 4.3-2.25 5v2c.478.56 1.787 1.508 3.188 2 1.875.66 2.812 2 2.812 4v1c0 .552-.449 1-1.007 1H9.507c-.556 0-1.007-.444-1.007-1v-1c0-2 .938-3.34 2.813-4 1.4-.492 2.709-1.44 3.187-2v-2c-1.161-.7-2.25-2.539-2.25-5 0-2.637 1.442-5 3.75-5zm8 0c1.846 0 3 1.89 3 4 0 1.969-.87 3.44-1.8 4v1.6c.382.447 1.43 1.206 2.55 1.6 1.5.527 2.25 1.6 2.25 3.2 0 .884-.902 1.6-1.982 1.6h-2.791c-.182-.613-.45-1.164-.795-1.655-.688-.978-1.698-1.745-3.081-2.232-.516-.181-1.023-.44-1.442-.778.109-.048.223-.093.341-.135 1.12-.394 2.168-1.153 2.55-1.6V14c-.93-.56-1.8-2.031-1.8-4 0-2.11 1.154-4 3-4zM8 6c1.846 0 3 1.89 3 4 0 1.969-.87 3.44-1.8 4v1.6c.382.447 1.43 1.206 2.55 1.6.143.05.279.105.408.165-.43.28-.965.557-1.509.748-1.383.487-2.393 1.254-3.08 2.232-.346.49-.614 1.042-.796 1.655H3.982C2.887 22 2 21.088 2 20.4c0-1.6.75-2.673 2.25-3.2 1.12-.394 2.168-1.153 2.55-1.6V14c-.93-.56-1.8-2.031-1.8-4 0-2.11 1.154-4 3-4z'
      />
    </svg>
  </i>
)

export const ContactsSingleIcon = () => (
  <i
    className='icon contacts-single'
    style={{ width: '100%', height: '100%', margin: 0 }}
  >
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <path
        fill='#161616'
        fillRule='evenodd'
        d='M17.6 18c.51.56 1.906 1.508 3.4 2 2 .66 3 2 3 4v.006C24 25.107 23.105 26 22.006 26H9.994C8.893 26 8 25.105 8 24c0-2 1-3.34 3-4 1.494-.492 2.89-1.44 3.4-2v-2c-1.239-.7-2.4-2.539-2.4-5 0-2.637 1.538-5 4-5 2.462 0 4 2.363 4 5 0 2.461-1.161 4.3-2.4 5v2z'
      />
    </svg>
  </i>
)

export const DropdownMultiIcon = () => (
  <i
    className='icon dropdown-multi'
    style={{ width: '100%', height: '100%', margin: 0 }}
  >
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <g fill='none' fillRule='evenodd'>
        <path fill='#FFF' d='M6 23H8V25H6z' />
        <path
          fill='#161616'
          d='M9.25 21c.414 0 .75.336.75.75v4.5c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75v-4.5c0-.414.336-.75.75-.75h4.5zM16 23v2h-2v-2h2zm4 0v2h-2v-2h2zm4 0v2h-2v-2h2zm4 0v2h-2v-2h2zM9.25 13c.414 0 .75.336.75.75v4.5c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75v-4.5c0-.414.336-.75.75-.75h4.5zM27 15c.552 0 1 .447 1 1 0 .552-.448 1-1 1H15c-.552 0-1-.448-1-1 0-.553.448-1 1-1h12zM9.25 5c.414 0 .75.336.75.75v4.5c0 .414-.336.75-.75.75h-4.5c-.414 0-.75-.336-.75-.75v-4.5c0-.414.336-.75.75-.75h4.5zM27 7c.552 0 1 .447 1 1 0 .552-.448 1-1 1H15c-.552 0-1-.448-1-1 0-.553.448-1 1-1h12zM6 23v2h2v-2H6z'
        />
      </g>
    </svg>
  </i>
)

export const DropdownSingleIcon = () => (
  <i
    className='icon dropdown-single'
    style={{ width: '100%', height: '100%', margin: 0 }}
  >
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <g fill='none' fillRule='evenodd'>
        <path fill='#FFF' d='M6 6H26V26H6z' />
        <path
          fill='#161616'
          d='M26 4c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2H6c-1.105 0-2-.895-2-2V6c0-1.105.895-2 2-2h20zm0 2H6v20h20V6zm-6 7c.552 0 1 .448 1 1 0 .216-.07.427-.2.6l-4 5.333c-.331.442-.958.532-1.4.2-.076-.057-.143-.124-.2-.2l-4-5.333c-.331-.442-.242-1.069.2-1.4.173-.13.384-.2.6-.2h8z'
        />
      </g>
    </svg>
  </i>
)

export const DurationIcon = () => (
  <i
    className='icon duration'
    style={{ width: '100%', height: '100%', margin: 0 }}
  >
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
      <path
        fill='#161616'
        fillRule='evenodd'
        d='M3 17c-.552 0-1-.448-1-1s.448-1 1-1h4c.552 0 1 .448 1 1s-.448 1-1 1H6c0 6.075 4.925 11 11 11s11-4.925 11-11c0-5.979-4.77-10.843-10.712-10.996L17 6v1c0 .552-.448 1-1 1s-1-.448-1-1V3c0-.552.448-1 1-1s1 .448 1 1v1c7.18 0 13 5.82 13 13s-5.82 13-13 13S4 24.18 4 17H3zm12.001 1.054h.001l-.001-.035v.035-.035L15 12c0-.552.448-1 1-1 .513 0 .936.386.993.883L17 12v5.585l3.707 3.708c.36.36.388.928.083 1.32l-.083.094c-.36.36-.928.388-1.32.083l-.094-.083-4-4-.006-.006c-.026-.026-.051-.055-.074-.084l-.017-.022c-.018-.024-.034-.049-.05-.074l-.021-.037c-.011-.02-.022-.04-.031-.06l-.023-.053-.021-.06-.014-.045-.016-.065-.01-.059-.007-.07-.002-.018z'
      />
    </svg>
  </i>
)
