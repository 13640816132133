import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ExitIcon } from '../common/icon/BasicIcons'
import { CheckboxIcon } from '../common/icon/ColumnIcons'
import { FieldTypes } from '../common/util/constants'
import { getTabPositionStyle } from '../common/util/docusign'
import { emitEvent } from '../common/util/events'
import { isInside } from '../common/util/format'
import { isDocuSignMapping, isNativeMapping } from '../common/util/mapping'

import './FormField.css'

// this component is slated for a future re-write and has limited test coverage; keep changes minimal
const FormField = ({ mapping, field, page, hidden, setFieldPairing }) => {
  const { t } = useTranslation()

  const [isDragging, setIsDragging] = useState(false)
  const [isDragHover, setIsDragHover] = useState(false)
  const [isMouseHover, setIsMouseHover] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const isColCompatible = useRef()
  const fieldRef = useRef()

  useEffect(() => {
    document.addEventListener('columndrag', dragListener)
    document.addEventListener('columndrop', dropListener)

    return () => {
      document.removeEventListener('columndrag', dragListener)
      document.removeEventListener('columndrop', dropListener)
    }
  }, [])

  useEffect(() => {
    setInputValue(getPairing().value)
    setIsDragHover(false)
  }, [mapping])

  // Should return the field pairing for this field, if it exists, or an empty
  // object if it does not
  const getPairing = () => {
    if (isNativeMapping(mapping)) {
      return mapping &&
        mapping.fieldPairings &&
        mapping.fieldPairings[field.objectNumber]
        ? mapping.fieldPairings[field.objectNumber]
        : {}
    }
    if (isDocuSignMapping(mapping)) {
      // A field derived from a DocuSign Tab will have the TabID
      // set as its ObjectNumber value.
      return mapping &&
        mapping.docusign &&
        mapping.docusign.tabPairings &&
        mapping.docusign.tabPairings[field.objectNumber]
        ? mapping.docusign.tabPairings[field.objectNumber]
        : {}
    }
    return {}
  }

  const getContent = () => {
    const { fieldType, name } = field
    if (fieldType !== FieldTypes.Checkbox) {
      return (
        <textarea
          // Disable input if field type is fully unsupported (e.g DocuSign sig)
          disabled={fieldType === FieldTypes.Unsupported}
          placeholder={name}
          title={name}
          value={inputValue || ''}
          onChange={handleInputChange}
          aria-label={t('formField.pdfField', {
            fieldName: name
          })}
        />
      )
    } else if (fieldType === FieldTypes.Checkbox) {
      const checked = inputValue === 'true'
      return (
        <div className='checkbox'>
          <input
            type='checkbox'
            defaultChecked={checked}
            onChange={handleInputChange}
            aria-label={t('formField.pdfCheckboxField', {
              fieldName: name
            })}
          />
          <span className='checkmark'>
            {checked && !isDragging ? <CheckboxIcon /> : ''}
          </span>
        </div>
      )
    }
  }

  const getClassName = () => {
    const pairing = getPairing()
    if (isDragHover)
      return `drag-hover ${isColCompatible.current ? 'valid' : 'invalid'}`
    if (isDragging)
      return `dragging ${isColCompatible.current ? 'valid' : 'invalid'}`
    if (pairing.type)
      return `mapped ${pairing.type}${isMouseHover ? ' hover' : ''}`
    return 'default'
  }

  //// Copied from https://github.com/mozilla/pdf.js/blob/master/src/shared/util.js#L688
  // Normalize rectangle rect=[x1, y1, x2, y2] so that (x1,y1) < (x2,y2)
  // For coordinate systems whose origin lies in the bottom-left, this
  // means normalization to (BL,TR) ordering. For systems with origin in the
  // top-left, this means (TL,BR) ordering.
  const normalizeRect = (rect) => {
    const r = rect.slice(0)
    if (rect[0] > rect[2]) {
      r[0] = rect[2]
      r[2] = rect[0]
    }
    if (rect[1] > rect[3]) {
      r[1] = rect[3]
      r[3] = rect[1]
    }
    return r
  }

  // Translates pageBox and rect for the field into styles positioning the field
  // correctly on the page, accounting for page rotation.
  const getPosition = () => {
    // DocuSign Tabs need to have their style calculated differently.
    if (field.tab) {
      return getTabPositionStyle(field.tab)
    }

    const pageBox = page.view || [0, 0, 0, 0]
    const pageRotation = page.rotate || 0
    const fieldRect = field.rect || [0, 0, 0, 0]
    const nRect = normalizeRect(fieldRect)

    if (pageRotation === 90) {
      return {
        left: nRect[1] - pageBox[1],
        bottom: pageBox[2] - nRect[2],
        width: Math.abs(nRect[3] - nRect[1]),
        height: Math.abs(nRect[2] - nRect[0])
      }
    } else if (pageRotation === 270) {
      return {
        left: pageBox[3] - nRect[3],
        bottom: nRect[0],
        width: Math.abs(nRect[1] - nRect[3]),
        height: Math.abs(nRect[0] - nRect[2])
      }
    } else {
      return {
        left: nRect[0] - pageBox[0],
        bottom: nRect[1] - pageBox[1],
        width: Math.abs(nRect[0] - nRect[2]),
        height: Math.abs(nRect[1] - nRect[3])
      }
    }
  }

  const deletePairing = () => {
    setInputValue('')
    setFieldPairing(field.objectNumber, null)
  }

  const handleInputChange = (event) => {
    let inputValue = event.target.value
    // Handle checkbox inputs
    if (field.fieldType === FieldTypes.Checkbox) {
      inputValue = event.target.checked ? 'true' : 'false'
    }
    // Map the field
    setInputValue(inputValue)
    setFieldPairing(field.objectNumber, {
      type: 'constant',
      value: inputValue
    })
  }

  const isCompatible = (column, field) => {
    const fieldType = field ? field.fieldType : ''
    const columnType = column ? column.type : ''
    switch (fieldType) {
      case FieldTypes.Text:
        switch (columnType) {
          case 'TEXT_NUMBER':
          case 'CONTACT_LIST':
          case 'MULTI_CONTACT_LIST':
          case 'PICKLIST':
          case 'MULTI_PICKLIST':
          case 'DATE':
          case 'DATETIME':
          case 'ABSTRACT_DATETIME':
          case 'DURATION':
          case 'PREDECESSOR':
            return true
          default:
            return false
        }
      case FieldTypes.MultiLineText:
        switch (columnType) {
          case 'TEXT_NUMBER':
          case 'CONTACT_LIST':
          case 'MULTI_CONTACT_LIST':
          case 'PICKLIST':
          case 'MULTI_PICKLIST':
          case 'DATE':
          case 'DATETIME':
          case 'ABSTRACT_DATETIME':
          case 'DURATION':
          case 'PREDECESSOR':
            return true
          default:
            return false
        }
      case FieldTypes.Choice:
        switch (columnType) {
          case 'TEXT_NUMBER':
          case 'CONTACT_LIST':
          case 'MULTI_CONTACT_LIST':
          case 'PICKLIST':
          case 'MULTI_PICKLIST':
          case 'DATE':
          case 'DATETIME':
          case 'ABSTRACT_DATETIME':
            return true
          default:
            return false
        }
      case FieldTypes.Checkbox:
        switch (columnType) {
          case 'CHECKBOX':
            return true
          default:
            return false
        }
      case FieldTypes.Image:
        switch (columnType) {
          case 'TEXT_NUMBER':
            return true
          default:
            return false
        }
      default:
        return false
    }
  }

  const dragListener = (event) => {
    // When user starts dragging a column card
    // calculate whether this field is compatible with it
    isColCompatible.current = isCompatible(event.detail.column, field)

    setIsDragging(true)
    setIsDragHover((previous) => {
      if (
        isInside(
          event.detail.pos.positionX,
          event.detail.pos.positionY,
          fieldRef,
          hidden
        )
      ) {
        emitEvent('fieldhover', {
          fieldType: field.objectNumber,
          compatible: isColCompatible.current
        })
        return true
      } else if (previous) {
        emitEvent('fieldhover', {})
        return false
      } else {
        return previous
      }
    })
  }

  const dropListener = () => {
    setIsDragging(false)
    setIsDragHover(false)
  }

  const positionStyle = getPosition()

  const wrapperStyle = {
    left: positionStyle.left,
    // Add 24px to the width to accomodate the "unpair-button"
    // the button should appear 24px * 24px on the right of the field
    width: positionStyle.width + 24,
    height: positionStyle.height
  }
  const contentStyle = {
    width: positionStyle.width
  }

  // DocuSign tabs are positioned relative to the top of the page
  if (positionStyle.top) {
    wrapperStyle.top = positionStyle.top
    // Acroform fields are position relative to the bottom of the page
  } else if (positionStyle.bottom) {
    wrapperStyle.bottom = positionStyle.bottom
  }

  return (
    <div className={'pdf-field'} style={wrapperStyle}>
      <div
        className={'content ' + getClassName()}
        ref={fieldRef}
        style={contentStyle}
        onMouseEnter={() => setIsMouseHover(true)}
        onMouseLeave={() => setIsMouseHover(false)}
      >
        {getContent()}
      </div>
      {isMouseHover && getPairing().value ? (
        <div
          className={`unpair-button ${getPairing().type}`}
          onMouseEnter={() => setIsMouseHover(true)}
          onMouseLeave={() => setIsMouseHover(false)}
        >
          <button
            onClick={deletePairing}
            data-dd-action-name='smar:form.field.delete.btn'
          >
            <ExitIcon t={t('formField.exitIcon')} />
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default FormField
