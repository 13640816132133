import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ErrorModal from '../common/ErrorModal'
import Spinner from '../common/Spinner'
import { selectError, setError } from '../common/store/error'
import auth from './authUtil'

// Captures the code from the OAuth callback, uses it to fetch a token from the
// API, then stores that token in LocalStorage.
const Callback = () => {
  const dispatch = useDispatch()
  const { error } = useSelector(selectError)

  const [authing, setAuthing] = useState(false)

  useEffect(() => {
    if (error) return
    const searchParams = new URLSearchParams(window.location.search)

    if (searchParams.get('error')) {
      return window.location.assign('/login')
    }

    setAuthing(true)

    // TODO: should this be part of smartfillApi?
    axios
      .post('/api/auth/token?code=' + searchParams.get('code'))
      .then((resp) => {
        auth.storeToken(resp.data)
        window.location.assign('/?' + searchParams.get('state'))
      })
      .catch((error) => dispatch(setError(error)))
  }, [])

  const modalClose = () => {
    window.location.replace('https://app.smartsheet.com')
  }

  return authing ? <Spinner /> : <ErrorModal onClose={modalClose} />
}

export default Callback
