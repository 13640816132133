import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
      customHeaders: {
        'Cache-Control': 'no-cache'
      },
      // Prevent caching of translation files by appending time string query parameter
      queryStringParams: { cb: new Date().getTime() }
    },
    cache: {
      enabled: false
    }
  })

const changeLanguage = (locale) => {
  locale = locale.replace('_', '-')

  if (i18n.language === locale) return

  i18n.changeLanguage(locale).then(() => {
    console.log(`Changed language to '${locale}'`)
  })
}

export default { changeLanguage }
