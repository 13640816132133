const defaultFeedbackURL =
  'https://app.smartsheet.com/b/form/739aa75f30ca43a8a22eb53e4da7d409?What%20is%20your%20feedback%20about%3F=Document%20Generation'

const helpURL = 'https://help.smartsheet.com/articles/2481651'

function getOrigin() {
  const env = window.applicationConfig.appEnvironment
  switch (env) {
    case 'prod':
      return 'com-us'
    case 'prod-eu':
      return 'com-eu'
    default:
      return ''
  }
}

function getPopulatedFeedbackURL(user) {
  if (!user) return defaultFeedbackURL

  const url = new URL(defaultFeedbackURL)
  const params = {
    'First Name': user.firstName,
    'Last Name': user.lastName,
    'Work Email': user.email,
    Origin: getOrigin()
  }

  for (let key in params) {
    const val = params[key]
    if (val) {
      url.searchParams.set(key, val)
    }
  }

  return url.toString()
}

export { helpURL, getPopulatedFeedbackURL }
