import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterButton,
  TypeRamp
} from '@smartsheet/lodestar-core'
import { AlertErrorAltIcon } from '@smartsheet/lodestar-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import './useConfirmationModal.css'

const useConfirmationModal = (defaultState = false) => {
  const [isModalOpen, setIsModalOpen] = useState(defaultState)
  const [props, setProps] = useState({})

  const openModal = (props) => {
    if (!props?.message) {
      throw new Error('an message key is required')
    }
    if (!props?.buttonHandlers) {
      throw new Error('a button handler object is required')
    }

    setProps(props)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const ConfirmationModal = () => {
    const { t } = useTranslation()

    const { message, data, buttonHandlers = {} } = props
    const { onCancel, onConfirm, onDoNotSave, onSave } = buttonHandlers

    const closeAndDo = (fn) => {
      fn()
      closeModal()
    }

    return (
      <Modal
        className='confirmation-modal'
        isOpen={isModalOpen}
        onCloseRequested={onCancel}
        shouldCloseOnOverlayClick={false}
        width='40rem'
      >
        <ModalBody className='modal-body'>
          <AlertErrorAltIcon size='large' className='icon' />
          <TypeRamp variant='headlineSmall' className='message'>
            {t(message, data)}
          </TypeRamp>
        </ModalBody>
        <ModalFooter className='modal-footer'>
          {onCancel && (
            <ModalFooterButton
              className='cancel-btn'
              appearance='secondary'
              data-dd-action-name='smar:confirmation.modal.cancel.btn'
              onClick={() => closeAndDo(onCancel)}
            >
              {t('confirmationModal.cancel')}
            </ModalFooterButton>
          )}
          {onConfirm && (
            <ModalFooterButton
              className='confirm-btn'
              appearance='primary'
              data-dd-action-name='smar:confirmation.modal.confirm.btn'
              onClick={() => closeAndDo(onConfirm)}
            >
              {t('confirmationModal.confirm')}
            </ModalFooterButton>
          )}
          {onDoNotSave && (
            <ModalFooterButton
              className='donotsave-btn'
              appearance='secondary'
              data-dd-action-name='smar:confirmation.modal.donotsave.btn'
              onClick={() => closeAndDo(onDoNotSave)}
            >
              {t('confirmationModal.doNotSave')}
            </ModalFooterButton>
          )}
          {onSave && (
            <ModalFooterButton
              className='save-btn'
              appearance='primary'
              data-dd-action-name='smar:confirmation.modal.save.btn'
              onClick={() => closeAndDo(onSave)}
            >
              {t('confirmationModal.save')}
            </ModalFooterButton>
          )}
        </ModalFooter>
      </Modal>
    )
  }

  return [ConfirmationModal, openModal, closeModal]
}

export default useConfirmationModal
