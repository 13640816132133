// DocuSignCallback is the page the user sees in the OAuth popup window when
// DocuSign redirects them back to Document Builder after completion of the
// OAuth consent form
import { Spinner } from '@smartsheet/lodestar-core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ErrorModal from '../common/ErrorModal'
import { postDocuSignCode } from '../common/SmartfillApi'
import { selectError, setError } from '../common/store/error'

const DocuSignCallback = () => {
  const dispatch = useDispatch()
  const { error } = useSelector(selectError)

  const [authing, setAuthing] = useState(false)

  useEffect(() => {
    if (error) return
    const searchParams = new URLSearchParams(window.location.search)
    const query = Object.fromEntries(searchParams)

    setAuthing(true)

    postDocuSignCode(query)
      .then(() => {
        setAuthing(false)
        window.close()
      })
      .catch((error) => {
        if (window.clientLogger) window.clientLogger.error(error.toString())
        setAuthing(false)
        dispatch(setError(error))
      })
  }, [])

  return authing ? <Spinner /> : <ErrorModal />
}

export default DocuSignCallback
