import PropTypes from 'prop-types'
import React from 'react'

import './ProgressBar.css'

export const progressType = {
  none: 'none',
  inProgress: 'in-progress',
  success: 'success',
  error: 'error'
}

const ProgressBar = ({ progressPercent, progressType }) => {
  // Check that percent used isn't < 0 or > 100.
  let fixedPercentage = 0

  if (progressPercent > 0) {
    fixedPercentage = progressPercent >= 100 ? 100 : progressPercent
  }

  return (
    <div
      className={`progress-bar ${progressType}`}
      role='progressbar'
      aria-valuenow={fixedPercentage}
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuetext={fixedPercentage + '%'}
      aria-label={fixedPercentage + '%'}
    >
      <div className='filler' style={{ width: `${fixedPercentage}%` }} />
    </div>
  )
}

ProgressBar.propTypes = {
  progressPercent: PropTypes.number.isRequired,
  progressType: PropTypes.string.isRequired
}

export default ProgressBar
