import {
  Button,
  ClickAwayListener,
  Flyout,
  useFlyout
} from '@smartsheet/lodestar-core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectCurrentMapping } from '../common/store/mapping'
import { MAPPING_TYPES, getMappingType } from '../common/util/mapping'

import './SettingsMenu.css'

const NativeMenu = ({
  toggleReplaceModal,
  setSettingsMenuIsOpen,
  isReplaceDisabled
}) => {
  const { t } = useTranslation()

  return (
    <div className='settings-menu' data-testid='settings-menu'>
      <div className='settings-menu-item'>
        <Button
          className='settings-menu-button replace'
          data-testid='replace-pdf-button'
          shouldFitContainer={true}
          appearance='secondary'
          onClick={() => {
            toggleReplaceModal()
            setSettingsMenuIsOpen(false)
          }}
          isDisabled={isReplaceDisabled}
        >
          {t('replaceModal.title')}
        </Button>
      </div>
    </div>
  )
}

const DocuSignMenu = ({
  emailPreviewerEnabled,
  setIsWizardOpen,
  setEmailPreviewerOpen
}) => {
  const { t } = useTranslation()

  return (
    <div className='settings-menu' data-testid='settings-menu'>
      <div className='settings-menu-item'>
        <Button
          className='settings-menu-button replace'
          data-testid='mapping-config-button'
          style={{ padding: '0.58em' }}
          appearance='secondary'
          onClick={() => setIsWizardOpen(true)}
        >
          {t('settingsMenu.mappingConfig')}
        </Button>
      </div>
      <div className='settings-menu-item'>
        <Button
          className='settings-menu-button replace'
          data-testid='email-previewer-button'
          style={{ padding: '0.58em' }}
          appearance='secondary'
          isDisabled={!emailPreviewerEnabled}
          onClick={() => setEmailPreviewerOpen(true)}
        >
          {t('settingsMenu.customEmail')}
        </Button>
      </div>
    </div>
  )
}

const SettingsMenu = (props) => {
  const { t } = useTranslation()
  const currentMapping = useSelector(selectCurrentMapping)

  const [settingsMenuIsOpen, setSettingsMenuIsOpen] = useState(false)

  const { targetProps, flyoutProps } = useFlyout({
    isOpen: settingsMenuIsOpen,
    onCloseRequested: () => setSettingsMenuIsOpen(false)
  })

  const mappingType = getMappingType(currentMapping)

  return (
    <div className='editor-control'>
      <Button
        className='single-select'
        data-testid='settings-menu-button'
        style={{ padding: '0.58em' }}
        appearance='secondary'
        {...targetProps}
        onClick={() => setSettingsMenuIsOpen((current) => !current)}
      >
        {t('docHandler.settings', 'Settings')}
      </Button>

      {/* All future settings options can be added as a button on this list */}
      <Flyout {...flyoutProps} placement='bottom-start'>
        <ClickAwayListener onClickAway={() => setSettingsMenuIsOpen(false)}>
          <div>
            {mappingType === MAPPING_TYPES.docusign && (
              <DocuSignMenu {...props} />
            )}
            {mappingType === MAPPING_TYPES.native && (
              <NativeMenu
                setSettingsMenuIsOpen={setSettingsMenuIsOpen}
                {...props}
              />
            )}
          </div>
        </ClickAwayListener>
      </Flyout>
    </div>
  )
}

export default SettingsMenu
