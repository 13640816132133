import React from 'react'

import './BasicIcon.css'

export const LogoutIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height='25'
    width='20'
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
    strokeWidth='2'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'
    />
  </svg>
)

export const ExitIcon = (props) => (
  <i aria-label={props.t} className='exit-icon icon'>
    <svg
      aria-label={props.t}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
    >
      <path
        fillRule='evenodd'
        d='M7.996 6.723l3.146-3.147c.195-.195.512-.195.707 0l.566.566c.195.195.195.512 0 .707L9.268 7.996l3.147 3.146c.195.195.195.512 0 .707l-.566.566c-.195.195-.512.195-.707 0L7.996 9.268l-3.147 3.147c-.195.195-.512.195-.707 0l-.566-.566c-.195-.195-.195-.512 0-.707l3.147-3.146-3.147-3.147c-.195-.195-.195-.512 0-.707l.566-.566c.195-.195.512-.195.707 0l3.147 3.147z'
      />
    </svg>
  </i>
)

export const ExclaimIcon = () => (
  <i className='exclaim-icon icon'>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
    >
      <g fillRule='evenodd'>
        <g transform='translate(-494 -452) translate(254 121) translate(240 259) translate(0 29) translate(0 40)'>
          <path
            d='M10 1.667c4.602 0 8.333 3.73 8.333 8.333 0 4.602-3.73 8.333-8.333 8.333-4.602 0-8.333-3.73-8.333-8.333 0-4.602 3.73-8.333 8.333-8.333zm0 11.666l-.092.005c-.419.046-.744.402-.741.833 0 .456.373.829.833.829.46 0 .833-.373.833-.833 0-.428-.322-.78-.737-.828L10 13.333zM10 5c-.46 0-.833.344-.833.77v5.127l.007.105c.055.375.404.665.826.665.46 0 .833-.345.833-.77V5.77l-.007-.104C10.77 5.289 10.422 5 10 5z'
            transform='translate(0 3)'
          />
        </g>
      </g>
    </svg>
  </i>
)

export const AlertIcon = () => (
  <i className='alert-icon icon'>
    <svg
      aria-hidden={false}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <defs>
        <filter id='oq20m38ypa'>
          <feColorMatrix
            in='SourceGraphic'
            values='0 0 0 0 0.066667 0 0 0 0 0.066667 0 0 0 0 0.066667 0 0 0 1.000000 0'
          />
        </filter>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g
            filter='url(#oq20m38ypa)'
            transform='translate(-506 -381) translate(494 359)'
          >
            <g>
              <path
                fill='#111'
                d='M14.029 4.139l7.659 13.404c.89 1.558-.235 3.497-2.029 3.497H4.341c-1.795 0-2.92-1.939-2.029-3.497L9.971 4.139c.897-1.571 3.161-1.571 4.058 0zm-2.03 11.236l-.11.006c-.502.055-.893.482-.889 1 0 .546.448.994 1 .994s1-.448 1-1c0-.513-.387-.936-.884-.993l-.117-.007zM12 8.63c-.414 0-.75.336-.75.75v3.74l.007.102c.05.366.363.648.743.648.414 0 .75-.336.75-.75V9.38l-.007-.102c-.05-.366-.363-.648-.743-.648z'
                transform='translate(12 22)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </i>
)

export const UpsellIcon = () => (
  <i className='upsell icon'>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
    >
      <defs>
        <filter id='85vsfk017a'>
          <feColorMatrix
            in='SourceGraphic'
            values='0 0 0 0 0.000000 0 0 0 0 0.478431 0 0 0 0 0.286275 0 0 0 1.000000 0'
          />
        </filter>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g filter='url(#85vsfk017a)' transform='translate(-20 -51)'>
          <g>
            <path
              fill='#111'
              fillRule='nonzero'
              d='M10 2c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm.424 4.14c-.249-.184-.587-.187-.839-.007l-.09.076L7.218 8.47l-.071.082c-.19.253-.196.603-.017.863l.076.093.08.072c.249.193.594.199.85.017l.09-.077 1.021-1.015v5.03l.007.099c.048.358.35.634.716.634.365 0 .668-.276.715-.634l.007-.1V8.446l1.081 1.074.082.07c.285.21.686.182.94-.082.25-.261.271-.663.065-.948l-.078-.09-2.277-2.261-.08-.07z'
              transform='translate(20 51)'
            />
            <path d='M0 0H20V20H0z' transform='translate(20 51)' />
          </g>
        </g>
      </g>
    </svg>
  </i>
)
