import deburr from 'lodash/deburr'
import escapeRegExp from 'lodash/escapeRegExp'

/**
 * returns title as an array of strings split by the searchText
 */
export const getLocalizedParts = (title, searchText) => {
  const escapedText = escapeRegExp(deburr(searchText))

  // split title string by search pattern (removes accents)
  let parts = searchText
    ? deburr(title).split(new RegExp(`(${escapedText})`, 'gi'))
    : [title]

  // reconstruct parts with original accented characters
  const output = []
  while (parts.length) {
    const partLength = parts.shift().length
    output.push(title.slice(0, partLength))
    title = title.slice(partLength)
  }

  return output
}

/**
 * returns true if part matches searchText (ignores accents per locale)
 */
export const isPartMatched = (part, searchText, locale) => {
  locale = locale.slice(0, 2)

  return (
    deburr(part).toLocaleLowerCase(locale) ===
    deburr(searchText).toLocaleLowerCase(locale)
  )
}
