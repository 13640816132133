import { checkFeatureAccess } from '../SmartfillApi'

const featureNames = ['DocuSign']

function loadFeatures() {
  const features = {}

  return Promise.all(
    featureNames.map((Name) =>
      checkFeatureAccess(Name).then((response) => {
        features[Name] = response.data.licensed
      })
    )
  )
    .then(() => Promise.resolve(features))
    .catch((error) => Promise.reject(error))
}

export { loadFeatures }
