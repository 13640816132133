import { datadogRum } from '@datadog/browser-rum'

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
function escapeRegExp(s) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

function parseOrigins(allowedOrigins) {
  const origins = []
  for (var origin of (allowedOrigins || '').split('|')) {
    // if origin has * then use regex
    if (origin.indexOf('*') !== -1) {
      origin = new RegExp(escapeRegExp(origin).replaceAll('\\*', '.*'))
    }
    origins.push(origin)
  }
  return origins
}

export default function dataDogInit() {
  const cfg = window.applicationConfig
  if (!cfg.ddRUMAppId || !cfg.ddRUMClientToken) {
    return
  }

  // DataDog RUM
  datadogRum.init({
    applicationId: cfg.ddRUMAppId,
    clientToken: cfg.ddRUMClientToken,
    site: cfg.ddSite,
    service: 'smartfill-ui',
    env: cfg.appEnvironment,
    version: cfg.appVersion,
    sampleRate: cfg.ddSampleRate,
    trackInteractions: cfg.ddTrackInteractions,
    allowedTracingOrigins: parseOrigins(cfg.ddRUMAllowedTracingOrigins),
    beforeSend:
      cfg.appEnvironment === 'dev'
        ? (event) => {
            const isDev = cfg.appVersion === '0.0.0-devel'
            // do not send any click event to DD
            // if `event.action.target.name` has no `smar:` prefix.
            if (handleEvent(event) === false) {
              console.log(
                'RUM: Bad event name',
                event,
                'expected action.target.name as <smar:*> but got',
                objValue(event, 'action.target.name')
              )
              return false
            }

            // do not send any event to DD from the developer computer
            if (isDev) {
              return false
            }
          }
        : handleEvent
  })
}

function handleEvent(event) {
  if (objValue(event, 'action.type') === 'click') {
    const prefix = 'smar:'
    const eventName = '' + objValue(event, 'action.target.name')

    if (!eventName.startsWith(prefix)) {
      return false
    }
    // remove prefix before sending to DD
    event.action.target.name = eventName.substr(prefix.length)
  }
}

function objValue(obj, key) {
  for (let p of key.split('.')) {
    if (obj && obj.hasOwnProperty(p)) {
      obj = obj[p]
    } else {
      return false
    }
  }

  return obj
}
