// Define the number of rows for which confirmation becomes required
const confirmationCount = Object.freeze({
  Attachments: 10,
  DocuSignEnvelopes: 10,
  Download: 100
})

export const msgKey = Object.freeze({
  Attachments: 'confirmationModal.manyAttachments',
  DocuSignEnvelopes: 'confirmationModal.manyEnvelopes',
  Download: 'confirmationModal.bigZip',
  Unsaved: 'confirmationModal.unsavedChanges'
})

export function getLimitExceeded(outputOption, rowCount) {
  if (!confirmationCount.hasOwnProperty(outputOption)) return false
  return rowCount >= confirmationCount[outputOption]
}
