import {
  Button,
  SearchInput,
  Select,
  Tooltip,
  TooltipBody,
  useTooltip
} from '@smartsheet/lodestar-core'
import {
  SortAscendingIcon,
  SortDescendingIcon
} from '@smartsheet/lodestar-icons'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { filterOptions } from './mappingFilter'
import { sortOptions } from './mappingSort'

import './SortAndFilterBar.css'

const SortAndFilterBar = ({
  sortOption,
  reverseSort,
  filterOption,
  searchText,
  onChangeSortOption,
  toggleReverseSort,
  onChangeFilterOption,
  setSearchText
}) => {
  const { tooltipProps, targetProps } = useTooltip()
  const { t } = useTranslation()

  return (
    <div className='sort-and-filter-bar'>
      <SearchInput
        value={searchText}
        onChange={setSearchText}
        className='search'
        placeholder={t('sortAndFilter.searchPlaceholder')}
        aria-label={t('sortAndFilter.searchPlaceholder')}
      />
      <Select
        value={{
          label: filterOption.labelSelected,
          value: filterOption.value
        }}
        options={filterOptions}
        getOptionLabel={(option) => t(option.label)}
        onChange={onChangeFilterOption}
        isClearable={false}
        isSearchable={false}
        className='filter'
        aria-label={t('sortAndFilter.filterLabel')}
      />
      <Select
        value={{
          label: sortOption.labelSelected,
          value: filterOption.value
        }}
        options={sortOptions}
        getOptionLabel={(option) => t(option.label)}
        onChange={onChangeSortOption}
        isClearable={false}
        isSearchable={false}
        className='sort'
        aria-label={t('sortAndFilter.sortLabel')}
      />
      <Button
        {...targetProps}
        appearance='secondary'
        onClick={toggleReverseSort}
        iconBefore={
          reverseSort ? <SortAscendingIcon /> : <SortDescendingIcon />
        }
        aria-label={t(
          reverseSort
            ? 'sortAndFilter.sortAscending'
            : 'sortAndFilter.sortDescending'
        )}
      />
      <Tooltip {...tooltipProps} placement='top'>
        <TooltipBody>
          {t(
            reverseSort
              ? 'sortAndFilter.sortAscending'
              : 'sortAndFilter.sortDescending'
          )}
        </TooltipBody>
      </Tooltip>
    </div>
  )
}

SortAndFilterBar.protoTypes = {
  sortOption: PropTypes.object.isRequired,
  reverseSort: PropTypes.bool.isRequired,
  filterOption: PropTypes.object.isRequired,
  searchText: PropTypes.string.isRequired,
  onChangeSortOption: PropTypes.func.isRequired,
  toggleReverseSort: PropTypes.func.isRequired,
  onChangeFilterOption: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired
}

export default SortAndFilterBar
