import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Trans } from 'react-i18next'

import PDFIcon from '../common/icon/PDFIcon'

import './Dropzone.css'

const Dropzone = (props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles[0]) {
        props.setFile(acceptedFiles[0])
      }
    },
    [props.setFile]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    }
  })

  const loader = (
    <div style={{ marginTop: '1.5em' }}>
      {props.uploading ? (
        <Trans i18nKey='dropzone.uploading'>Uploading Document</Trans>
      ) : (
        ''
      )}
      {props.downloading ? (
        <Trans i18nKey='dropzone.downloading'>Downloading Document</Trans>
      ) : (
        ''
      )}
    </div>
  )

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <div className='drop-zone'>
        {!props.uploading && !props.downloading ? (
          <div className='info'>
            <div className='drop-zone-icon'>
              <PDFIcon />
            </div>
            <p className='drop-zone-label'>
              <Trans i18nKey='dropzone.label'>
                Drag and drop, or click to upload your PDF
              </Trans>
            </p>
            <input {...getInputProps()} />
          </div>
        ) : (
          loader
        )}
      </div>
    </div>
  )
}

export default Dropzone
