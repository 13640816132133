import {
  Banner,
  BannerActions,
  BannerContent,
  BannerDismissButton
} from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const NotificationRibbon = ({ messageKey }) => {
  const { t } = useTranslation()
  const [dismissed, setDismissed] = useState(false)

  return (
    <>
      {!dismissed && (
        <Banner data-testid='notification-ribbon' type='warning'>
          <BannerContent>{t(messageKey)}</BannerContent>
          <BannerActions>
            <BannerDismissButton
              onClick={() => setDismissed(true)}
              data-dd-action-name='smar:notification.ribbon.dismiss.btn'
              aria-label='close notification banner'
            />
          </BannerActions>
        </Banner>
      )}
    </>
  )
}

NotificationRibbon.proptypes = {
  messageKey: PropTypes.string.isRequired
}

export default NotificationRibbon
