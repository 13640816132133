import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import isPlainObject from 'lodash/isPlainObject'

import { parseServerError } from '../util/errors'
import { selectEnv } from './app'

const initialState = {
  error: null
}

export const setError = createAsyncThunk(
  'error/setError',
  async (error, { getState }) => {
    const env = selectEnv(getState())

    if (error.response?.data instanceof Blob) {
      try {
        const json = await error.response.data.text()
        error.response.data = JSON.parse(json)
      } catch (err) {
        // NOTE: Blob.text() may error if the data its parsing isn't in unicode
        // (can't confirm or deny if it errors silently). JSON.parse will error if it's data
        // is malformed. Will report the secondary error, then the original error will surface
        // immediately after the parsing error is fixed.
        error = err
      }
    }

    if (env === 'dev') {
      console.error(error)
    }

    // If dealing with a native Error object it must be converted first to a plain object
    // or Redux will throw a "can't store non-serializable object" error when saving to the store.
    if (!isPlainObject(error) && error?.name === 'Error') {
      error = { message: error.message, stack: error.stack, name: error.name }
    }

    return {
      error: parseServerError(error)
    }
  }
)

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setError.fulfilled, (state, action) => {
      state.error = action.payload.error
    })
  }
})

export const selectError = (state) => state.error

export const { clearError } = errorSlice.actions

export default errorSlice.reducer
