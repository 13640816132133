import React from 'react'

const PDFIcon = () => (
  <i className='pdf-icon'>
    <svg
      width='94px'
      height='104px'
      viewBox='0 0 94 104'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='card/create-new' transform='translate(-382.000000, -51.000000)'>
          <g id='Group-2'>
            <g
              id='illustration/document-pdf'
              transform='translate(384.000000, 53.000000)'
            >
              <g id='Group-3' transform='translate(-2.000000, -2.000000)'>
                <g id='Group' fillRule='nonzero'>
                  <g id='Group-4'>
                    <path
                      d='M70.1715729,2 L4,2 C2.8954305,2 2,2.8954305 2,4 L2,100 C2,101.104569 2.8954305,102 4,102 L90,102 C91.1045695,102 92,101.104569 92,100 L92,23.8284271 C92,23.2979941 91.7892863,22.7892863 91.4142136,22.4142136 L71.5857864,2.58578644 C71.2107137,2.21071368 70.7020059,2 70.1715729,2 Z'
                      id='Path'
                      fill='#E5EFFC'
                    ></path>
                    <path
                      d='M70.1715729,0 L70.3981547,0.00641663264 C71.3764203,0.0618810422 72.3034363,0.475009184 73,1.17157288 L92.8284271,21 C93.5785726,21.7501455 94,22.7675612 94,23.8284271 L94,100 C94,102.209139 92.209139,104 90,104 L4,104 C1.790861,104 0,102.209139 0,100 L0,4 C0,1.790861 1.790861,0 4,0 L70.1715729,0 Z M70.1715729,2 L4,2 C2.8954305,2 2,2.8954305 2,4 L2,100 C2,101.104569 2.8954305,102 4,102 L90,102 C91.1045695,102 92,101.104569 92,100 L92,23.8284271 C92,23.5400042 91.9376995,23.2580046 91.8205509,23.0004198 L74,23 C72.3431458,23 71,21.6568542 71,20 L71,20 L71.000567,2.1798982 C70.7427157,2.0624597 70.4603642,2 70.1715729,2 Z M90,21 L73,4 L73,20 C73,20.5128358 73.3860402,20.9355072 73.8833789,20.9932723 L74,21 L90,21 Z'
                      id='Combined-Shape'
                      fill='#005EE0'
                    ></path>
                  </g>
                  <path
                    d='M26.1666667,55.0972222 L26.1666667,62.2222222 L22,62.2222222 L22,42 L29.8888889,42 C31.407415,42 32.7430498,42.277775 33.8958333,42.8333333 C35.0486169,43.3888917 35.9351821,44.1782356 36.5555556,45.2013889 C37.175929,46.2245422 37.4861111,47.3888824 37.4861111,48.6944444 C37.4861111,50.6759358 36.8078772,52.2384202 35.4513889,53.3819444 C34.0949006,54.5254687 32.2176046,55.0972222 29.8194444,55.0972222 L26.1666667,55.0972222 Z M26.1666667,51.7222222 L29.8888889,51.7222222 C30.9907462,51.7222222 31.8310156,51.4629656 32.4097222,50.9444444 C32.9884288,50.4259233 33.2777778,49.68519 33.2777778,48.7222222 C33.2777778,47.7314765 32.986114,46.9305586 32.4027778,46.3194444 C31.8194415,45.7083303 31.013894,45.3935186 29.9861111,45.375 L26.1666667,45.375 L26.1666667,51.7222222 Z M40.3472222,62.2222222 L40.3472222,42 L46.5694444,42 C48.3472222,42 49.937493,42.400459 51.3402778,43.2013889 C52.7430626,44.0023188 53.837959,45.1411963 54.625,46.6180556 C55.412041,48.0949148 55.8055556,49.7731388 55.8055556,51.6527778 L55.8055556,52.5833333 C55.8055556,54.4629724 55.4189853,56.1342519 54.6458333,57.5972222 C53.8726813,59.0601925 52.7824144,60.1944404 51.375,61 C49.9675856,61.8055596 48.3796385,62.2129629 46.6111111,62.2222222 L40.3472222,62.2222222 Z M44.5138889,45.375 L44.5138889,58.875 L46.5277778,58.875 C48.1574156,58.875 49.4027735,58.3425979 50.2638889,57.2777778 C51.1250043,56.2129576 51.5648147,54.6898247 51.5833333,52.7083333 L51.5833333,51.6388889 C51.5833333,49.5833231 51.1574117,48.0254683 50.3055556,46.9652778 C49.4536994,45.9050873 48.2083415,45.375 46.5694444,45.375 L44.5138889,45.375 Z M71,53.9583333 L63,53.9583333 L63,62.2222222 L58.8333333,62.2222222 L58.8333333,42 L72,42 L72,45.375 L63,45.375 L63,50.5972222 L71,50.5972222 L71,53.9583333 Z'
                    id='PDF'
                    fill='#005EE0'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </i>
)

export default PDFIcon
