import { Button } from '@smartsheet/lodestar-core'
import { CloseIcon } from '@smartsheet/lodestar-icons'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  BATCH_JOB_TYPE,
  deleteBatchJobStatus,
  selectBatchJobStatus
} from '../common/store/batchJobStatus'
import ProgressBar, { progressType } from './ProgressBar'

import './BatchJobStatusItem.css'

const BatchJobStatusItem = ({ batchJobStatusId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const batchJobStatus = useSelector(selectBatchJobStatus(batchJobStatusId))

  const parseJobError = (t, errorString) => {
    let errorObject = null

    try {
      errorObject = JSON.parse(errorString)
    } catch (e) {
      // If errorString isn't JSON, nothing to parse
      return errorString
    }

    const errorCode = errorObject['code'] || '0.msg'
    const errorData = errorObject['data'] || {}

    // Errors straight from DocuSign will be present in the template field
    if (errorObject['template']) return errorObject['template']

    return t(`errors.${errorCode}`, errorData)
  }

  let itemsProcessed = 0
  let complete = false
  let success = false
  let error = false

  if (batchJobStatus.isUpdateStarted) {
    itemsProcessed = batchJobStatus.itemsComplete + batchJobStatus.itemsFailed
    complete = itemsProcessed === batchJobStatus.itemsTotal
    success = batchJobStatus.itemsComplete === batchJobStatus.itemsTotal
    error = !!batchJobStatus.errors?.length
  }

  // Label for below progress bar.
  const labelString = t(
    !error ? 'jobStatusItem.label' : 'jobStatusItem.label_failures',
    {
      processed: itemsProcessed,
      total: batchJobStatus.itemsTotal,
      failed: batchJobStatus.itemsFailed
    }
  )

  let progress = progressType.none

  if (error) {
    progress = progressType.error
  } else if (success) {
    progress = progressType.success
  } else if (batchJobStatus.isUpdateStarted) {
    progress = progressType.inProgress
  }

  const jobType = batchJobStatus?.jobType
  const sheetName = batchJobStatus?.sheetName
  // Set item header string
  let headerString = ''

  if (jobType === BATCH_JOB_TYPE.downloads) {
    // Header string for Download Jobs
    headerString = t('jobStatusItem.native.downloads.header', {
      context: complete ? 'complete' : '',
      mappingName: batchJobStatus.mappingName
    })
  } else if (jobType === BATCH_JOB_TYPE.attachments) {
    // Header string for Attachment Jobs
    headerString = t('jobStatusItem.native.attachments.header', {
      context: complete ? 'complete' : '',
      mappingName: batchJobStatus.mappingName,
      sheetName
    })
  } else if (jobType === BATCH_JOB_TYPE.docuSignEnvelopes) {
    // Header string for Envelope creation jobs
    headerString = t('jobStatusItem.docusign.envelopes.header', {
      context: complete ? 'complete' : '',
      mappingName: batchJobStatus.mappingName
    })
  } else if (jobType === BATCH_JOB_TYPE.docuSignEnvelopeDrafts) {
    // Header string for Envelope draft creation jobs
    headerString = t('jobStatusItem.docusign.drafts.header', {
      context: complete ? 'complete' : '',
      mappingName: batchJobStatus.mappingName
    })
  }

  return (
    <div className='job-status-item' data-testid='job-status-item'>
      <div className='job-status-heading'>
        <div className='job-name'>{headerString}</div>
        <div className='job-clear'>
          <Button
            onClick={() =>
              dispatch(
                deleteBatchJobStatus({ batchJobStatusId: batchJobStatus.id })
              )
            }
            data-dd-action-name='smar:job.status.item.remove.btn'
            aria-label={t('errorModal.dismiss')}
            appearance={'borderless'}
            style={{
              color: '#444444',
              marginTop: '-1.1em',
              marginLeft: 'auto',
              width: '30px',
              height: '30px'
            }}
          >
            <CloseIcon size='small' />
          </Button>
        </div>
      </div>
      <div className='job-progress'>
        <ProgressBar
          progressPercent={
            batchJobStatus.itemsTotal > 0
              ? (itemsProcessed / batchJobStatus.itemsTotal) * 100
              : 0
          }
          progressType={progress}
        />
        <div className='status-message'>{labelString}</div>
      </div>
      <ul className='job-errors' data-testid='batch-job-error-list'>
        {batchJobStatus.errors &&
          batchJobStatus.errors.map((errString, key) => (
            <li key={key}>{parseJobError(t, errString)}</li>
          ))}
      </ul>
    </div>
  )
}

BatchJobStatusItem.propTypes = {
  batchJobStatusId: PropTypes.string.isRequired
}

export default BatchJobStatusItem
