import { Radio, TypeRamp } from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { DocuSignUpsellWrapper } from '../../common/UpsellWrapper'
import { selectFeatureEnabledMap, selectSheet } from '../../common/store/app'
import {
  selectCurrentMapping,
  updateCurrentMapping
} from '../../common/store/mapping'
import { MAPPING_TYPES, getMappingType } from '../../common/util/mapping'
import { userCanCreateDocuSignMappings } from '../../common/util/permissions'

import './MappingTypeSelector.css'

const MappingTypeSelector = ({ isInitialized, setComplete }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const featureEnabledMap = useSelector(selectFeatureEnabledMap)
  const sheet = useSelector(selectSheet)
  const currentMapping = useSelector(selectCurrentMapping)

  const [isUpsellOpen, setIsUpsellOpen] = useState(false)

  const licensedToDocuSign = featureEnabledMap.DocuSign

  const handleSelectionChange = (event) => {
    const value = event.target.value

    if (value === 'docusign' && !licensedToDocuSign) {
      return setIsUpsellOpen(true)
    }

    setMappingType(value)
  }

  const setMappingType = (type) => {
    if (type === MAPPING_TYPES.native) {
      dispatch(
        updateCurrentMapping({
          document: {},
          docusign: null
        })
      )
      setComplete(true)
    }
    if (type === MAPPING_TYPES.docusign) {
      dispatch(
        updateCurrentMapping({
          document: null,
          docusign: {}
        })
      )
      setComplete(true)
    }
  }

  const mappingType = getMappingType(currentMapping)

  return (
    <div className='mapping-type-selector'>
      <TypeRamp variant='headlineMedium' renderAs='h2'>
        {t('wizard.screens.mappingType.header')}
      </TypeRamp>
      <div data-dd-action-name='smar:wizard.mapping.type.native.btn'>
        <TypeRamp variant='headlineSmall'>
          {t('wizard.screens.mappingType.pdf')}
        </TypeRamp>
        <Radio
          name='mapping-type-native'
          value='native'
          aria-label={t('wizard.screens.mappingType.uploadPDF')}
          label={t('wizard.screens.mappingType.uploadPDF')}
          isChecked={mappingType === 'native'}
          isDisabled={isInitialized}
          onChange={handleSelectionChange}
          data-dd-action-name='smar:wizard.mapping.type.native.btn'
        />
      </div>
      <br />
      <div data-dd-action-name='smar:wizard.mapping.type.esign.btn'>
        <TypeRamp variant='headlineSmall'>
          {t('wizard.screens.mappingType.eSign')}
        </TypeRamp>
        {!licensedToDocuSign && (
          <>
            <br />
            <TypeRamp variant='headerLarge'>
              {t('wizard.screens.mappingType.upsellSubheader')}
            </TypeRamp>
          </>
        )}
        <Radio
          name='mapping-type-docusign'
          value='docusign'
          aria-label={t('wizard.screens.mappingType.importDocuSign')}
          label={
            <>
              {t('wizard.screens.mappingType.importDocuSign')}
              {!licensedToDocuSign && (
                <DocuSignUpsellWrapper
                  context='wizard'
                  hardOpen={isUpsellOpen}
                  close={() => setIsUpsellOpen(false)}
                />
              )}
            </>
          }
          isChecked={mappingType === 'docusign'}
          isDisabled={isInitialized || !userCanCreateDocuSignMappings(sheet)}
          onChange={handleSelectionChange}
          data-dd-action-name='smar:wizard.mapping.type.esign.btn'
        />
      </div>
    </div>
  )
}

MappingTypeSelector.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
  setComplete: PropTypes.func.isRequired
}

export default MappingTypeSelector
