const getRecipientPairingStr = ({
  roleName,
  name,
  signerName,
  email,
  recipientType,
  t
}) => {
  const getRecType = () =>
    t('wizard.screens.docusign.roleAssigner.type', {
      context: recipientType
    })

  // Using spans here to make testing text easier.
  return `${roleName} (${getRecType()}): ${name || signerName || ''} ${
    email ? `(${email})` : ''
  }`
}

const getNameColumn = (sheet, pairing) =>
  sheet.columns.find((col) => {
    const columnId = col.id || col.virtualId
    return (
      columnId === pairing.contactColumnId || columnId === pairing.nameColumnId
    )
  })

const getEmailColumn = (sheet, pairing) =>
  sheet.columns.find((col) => {
    const columnId = col.id || col.virtualId
    return (
      columnId === pairing.contactColumnId || columnId === pairing.emailColumnId
    )
  })

export const formatRecipientPairing = (rec, currentMapping, sheet, t) => {
  const pairing = currentMapping.docusign.recipients[rec.recipientId]
  if (!pairing) return ''
  // if user data comes from docusign
  if (pairing.useDocuSignValues) {
    return getRecipientPairingStr({
      ...rec,
      key: rec.recipientId,
      t
    })
  }
  // if user data comes from smartsheet
  if (pairing.contactColumnId || pairing.nameColumnId) {
    return getRecipientPairingStr({
      ...rec,
      key: rec.recipientId,
      name: getNameColumn(sheet, pairing)?.title,
      email: getEmailColumn(sheet, pairing)?.title,
      t
    })
  }
}
