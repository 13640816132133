import { LodestarGlobalStyles } from '@smartsheet/lodestar-core'
import React, { Suspense, useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import authUtil from './auth/authUtil'
import Router from './common/Router'
import Spinner from './common/Spinner'
import i18n from './common/i18n'
import {
  fetchAppData,
  selectSmartsheetObject,
  selectUser
} from './common/store/app'
import {
  fetchDocusignAuth,
  fetchDocusignTemplateList
} from './common/store/docusign'
import { setError } from './common/store/error'
import { fetchMappingList } from './common/store/mapping'
import { resetStore } from './common/store/store'
import datadogUtils from './common/util/datadogUtils'

import './App.css'

const App = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const user = useSelector(selectUser)
  const smartsheetObject = useSelector(selectSmartsheetObject)

  const [isLoading, setIsLoading] = useState(false)
  const [isDocusignLoading, setIsDocusignLoading] = useState(false)

  useEffect(() => {
    if (user) return // logged in users can be on any route

    const unrestrictedRoutes = [
      '/login',
      '/auth/callback',
      '/auth/callback/docusign'
    ]

    if (unrestrictedRoutes.includes(location.pathname)) return

    if (!authUtil.checkToken()) {
      return history.replace('/login' + location.search)
    }

    if (location.search) {
      setIsLoading(true)
      dispatch(fetchAppData(new URLSearchParams(location.search)))
        .unwrap()
        .then(({ smartsheetObject }) => {
          return dispatch(fetchMappingList(smartsheetObject)).unwrap()
        })
        .catch((error) => {
          // If bad/old token clear all auth info and redirect to signin to try and fix it.
          if (error.message === 'Request failed with status code 401') {
            authUtil.logout()
            return history.replace('/login' + location.search)
          }

          dispatch(setError(error))
          dispatch(resetStore())
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [location, user])

  useEffect(() => {
    if (!smartsheetObject) return
    // silently attempt to fetch global docusign data
    setIsDocusignLoading(true)
    Promise.all([
      dispatch(fetchDocusignTemplateList(smartsheetObject)).unwrap(),
      dispatch(fetchDocusignAuth(smartsheetObject)).unwrap()
    ])
      .catch(() => {
        // DocuSign user is either unauthenticated or API is down so swallow error.
        return
      })
      .finally(() => {
        setIsDocusignLoading(false)
      })
  }, [smartsheetObject])

  useEffect(() => {
    if (!user) return

    i18n.changeLanguage(user.locale)
    datadogUtils.setDataDogUser(user)
  }, [user])

  return (
    <div className='app'>
      <LodestarGlobalStyles />
      {/* StrictMode can't be used until 3rd party semantic-ui-react is in
    strict compliance, will be when it hits v3 release */}
      {/* <React.StrictMode> */}
      {/* Suspense included here only so Translation will work. */}
      <Suspense fallback={<Spinner />}>
        {isLoading || isDocusignLoading ? <Spinner /> : <Router />}
      </Suspense>
      {/* </React.StrictMode> */}
    </div>
  )
}

export default App
