import { TypeRamp } from '@smartsheet/lodestar-core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import AlertBar from '../../common/AlertBar'
import DropZone from '../../common/Dropzone'
import { uploadPDF } from '../../common/SmartfillApi'
import {
  selectCurrentMapping,
  updateCurrentMapping
} from '../../common/store/mapping'
import { getErrorDetails, parseServerError } from '../../common/util/errors'

const Uploader = ({ setComplete }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const currentMapping = useSelector(selectCurrentMapping)
  const [error, setError] = useState()
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    if (currentMapping.document.id) {
      setComplete(true)
    }
  }, [currentMapping])

  const upload = (event) => {
    setIsUploading(true)
    uploadPDF(event)
      .then((response) => {
        dispatch(
          updateCurrentMapping({
            name: `Mapping for ${event.name}`,
            document: response.data
          })
        )
      })
      .catch((error) => {
        setError(parseServerError(error))
        setIsUploading(false)
      })
  }

  const errorDetails = error ? getErrorDetails(t, error) : {}

  return (
    <div className='uploader'>
      <TypeRamp variant='headlineMedium' renderAs='h2'>
        {t('sheetSelector.suggestion_nodocNoMappings')}
      </TypeRamp>
      <DropZone uploading={isUploading} setFile={upload} />
      {error && <AlertBar>{errorDetails.msg}</AlertBar>}
    </div>
  )
}

Uploader.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
  setComplete: PropTypes.func.isRequired
}

export default Uploader
